import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { RegisterComponent } from './pages/register/register.component';
import { OtpComponent } from './pages/otp/otp.component';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent, 
    title: 'Login' 
  },
  { 
    path: 'register', 
    component: RegisterComponent, 
    title: 'Cadastro' 
  },
  { 
    path: 'otp/verification/:hashid', 
    component: OtpComponent, 
    title: 'Cadastro' 
  },
  { 
    path: 'error', 
    component: ErrorComponent, 
    title: 'Error' 
  },
  { 
    path: '',   
    redirectTo: 'login', 
    pathMatch: 'full' 
  },
  { 
    path: 'requester',  
    loadChildren: () => import('./requester/requester.module').then(m => m.RequesterModule) 
  },
  { 
    path: 'screening',  
    loadChildren: () => import('./screening/screening.module').then(m => m.ScreeningModule) 
  },
  { 
    path: 'leadership', 
    loadChildren: () => import('./leadership/leadership.module').then(m => m.LeadershipModule) 
  },
  { 
    path: 'analyst',    
    loadChildren: () => import('./analyst/analyst.module').then(m => m.AnalystModule) 
  },
  { 
    path: '**', 
    redirectTo: 'error' 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
