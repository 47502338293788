import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DemandEventsService } from '../../services/demands/demand-events.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../services/user-management/user.service';
import Swal from 'sweetalert2';
import { EventCalendarComponent } from '../event-calendar/event-calendar.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit{

  @ViewChild('eventsList') private eventsList!: ElementRef;
  @Input() demand_id;
  events:any = [];
  event:any = null
  currentUser:any;
  @ViewChild('content') private calendarContent!: ElementRef;

  constructor(
    private demandEventsService: DemandEventsService,
    private userService: UserService,
    private modalService: NgbModal,
  ){}

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    this.getEvents();
  }

  getEvents() { 
    this.demandEventsService.getByDemandId(this.demand_id).subscribe({
      next: (result) => {
        console.log(result)
        this.events = result
        setTimeout(() => {
          this.scrollToBottom();
        }, 1000);
      },
      error: (err) => console.log(err)
    })
  }

  scrollToBottom() {
    if (this.eventsList) {
      this.eventsList.nativeElement.scrollTo({
        top: this.eventsList.nativeElement.scrollHeight,
        behavior: 'smooth' 
      });
    }
  }


  isValid (event) { 
    if(event.name && event.description && event.user) return true;
    else return false;
  }
  

  // showDemand(demand_id: number, content: any) {
  //   this.demand_id = demand_id
  //   this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  // }

  addEvent() { 
    if(!this.events.some(event => event.new)) {
      this.events.push({
        name: '',
        description: '',
        rrule: '',
        demand_id: this.demand_id,
        user: this.currentUser,
        user_id: this.currentUser.id,
        new: true
      })
      setTimeout(() => {
        this.scrollToBottom();
      }, 300);
      console.log(this.events);
    }
  }
  
  update(result) {
    console.log(result);
    let index = this.events.findIndex(event => event.id === result.id);
    if (index !== -1) {
      console.log("atualizou um existente");
      this.events[index] = result;
    } else {
      index = this.events.findIndex(event => event.new === true);
      if(index !== -1 ){ 
        console.log("salvou um novo");
        this.events[index] = result;
      }
    }
  }
  
  save(event) { 
    console.log(event);
    console.log(this.event);
    this.demandEventsService.save(this.event).subscribe({
      next: (result) => { 
        console.log(result);
        this.update(result);
      },
      error: (err) => console.log(err)
    })
  }

  delete(event_id:number) { 
    console.log(event_id);
    Swal.fire({
      icon: 'warning',
      text: `Deseja remover a atividade?`,
      showCancelButton: true,
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#f5365c',
    }).then(result => {
      if (result.value) {
        this.demandEventsService.deleteById(event_id).subscribe({
          next: (result) => { 
            this.getEvents();
          },
          error: (err) => console.log(err)
        })
      }
    })
  }

  deleteFromArray(i) { 
    this.events.splice(i, 1);
  }

  // showCalendar() {
  //   this.modalService.open(EventCalendarComponent, {windowClass: 'modal-rounded modal-event-calendar custom-modal-backdrop', size: 'md' });
  // }

  showCalendar() {
    this.modalService.open(this.calendarContent, {windowClass: 'modal-rounded modal-event-calendar', size: 'md' });
  }

  
}
