import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form } from '@angular/forms';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { FilesService } from '../../services/demands/files.service';
import Swal from 'sweetalert2';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {


  //files
  @Input() formData: FormData | undefined;
  @Input() style: number = 1;
  @Input() demandId:any = null;
  @Input() files: any[] = [];
  @Input() context: any;
  @Input() allowedExtensions = ['jpeg','png','jpg','gif','svg','mp4','avi','mov','doc','docx','ppt','pptx','pdf','xlsx'];

  @Output() saved = new EventEmitter();
  loading = false;

  public readonly MAX_FILE_SIZE_MB: number = 5;
  public greaterFiles: string[] = [];

  //user
  currentUser:any

  constructor(
    private fileService: FilesService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
  }

  public dropped(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => this.handleFile(file, droppedFile));
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(`Directory dropped: ${droppedFile.relativePath}`, fileEntry);
      }
    }
  }

  private handleFile(file: File, droppedFile: NgxFileDropEntry): void {
    const fileSizeMB = file.size / (1024 * 1024);
    const fileExtension = this.getFileExtension(droppedFile.relativePath)?.toLowerCase();
  
    // Verifica o tamanho do arquivo
    if (fileSizeMB > this.MAX_FILE_SIZE_MB) {
      this.showErrorMessage(`O arquivo ${droppedFile.relativePath} é muito grande. O tamanho máximo permitido é ${this.MAX_FILE_SIZE_MB}.`);
      return;
    }
  
    // Verifica o formato do arquivo
    if (!fileExtension || !this.allowedExtensions.includes(fileExtension)) {
      this.showErrorMessage(`O arquivo ${droppedFile.relativePath} não é uma imagem permitida. Formatos permitidos: ${this.allowedExtensions.join(', ')}`);
      return;
    }
  
    // Limpa os arquivos grandes
    this.greaterFiles = [];
  
    const fileName = droppedFile.relativePath;
  
    // Se um formData for passado como Input()
    if (this.formData) {
      this.appendFileToFormData(file, fileName);
      this.files.push(droppedFile);
    } else if (this.context) {
      this.uploadFileDirectly(file, this.context, fileName);
    } else {
      return;
    }
  }

  private showErrorMessage(message: string): void {
    Swal.fire({
      icon: 'error',
      title: 'Erro no upload',
      text: message,
      confirmButtonText: 'Ok'
    });
  }

  private appendFileToFormData(file: File, fileName: string): void {
    this.formData?.append('files[]', file, fileName);
  }

  private uploadFileDirectly(file: File, context:any, fileName: string): void {
    this.loading = true;
    const formData = new FormData();
    formData.append('files[]', file, fileName);
    this.fileService.save(formData, context.context, context.id).subscribe({
      next: (result:any) => { 
        console.log(result);
        console.log(result.files);
        this.files = result.files;
        this.loading = false;
        this.saved.emit(true);
      },
      error: (error:any) => console.error('Erro no upload do arquivo', error)
    });
  }

  public fileOver(event: any): void {
    console.log('File over event:', event);
  }

  public fileLeave(event: any): void {
    console.log('File leave event:', event);
  }

  getFileExtension(fileName:string) {
    return fileName.split('.').pop();
  }

}
