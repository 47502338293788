<div class="card z-index-2 h-100 mt-4">
  <div class="card-header pb-2 pt-3 bg-transparent">
    <div class="row align-items-center table-filter">
      <div class="col-3 d-flex align-items-center">
        <h6 class="text-capitalize text-xs mb-0">{{ title }}</h6>
        <div class="btn btn-primary btn-xs ms-auto mb-0" (click)="newDemand(create_demand)" *ngIf="addNew">+ Adicionar Demanda</div>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <select class="form-control" #order (change)="orderItens(order.value)">
          <option value="desc">Mais recentes</option>
          <option value="asc">Mais antigas</option>
        </select>
      </div>
      <!-- <div class="col-2 d-flex justify-content-end" *ngIf="!['analyst'].includes(currentUser.group.name)">
        <select name="" id="" class="form-control" #area (change)="getByAreaId(area.value)">
          <option value="0">Filtre por área</option>
          <option [value]="area.id" *ngFor="let area of areas">{{ area.name }}</option>
        </select>
      </div> -->
      <div class="col-2 d-flex justify-content-end">
        <input type="date" class="form-control" placeholder="Filtre por data" (change)="getByDate($event)">
      </div>
      <div class="col-3 d-flex justify-content-end">
        <input type="text" placeholder="Busque aqui" (keyup.enter)="searchItens(search.value)" #search class="form-control">
      </div>
      <!-- <div class="col-4 d-flex justify-content-end">
        <a routerLink="/calendar" class="btn btn-xs btn-primary mb-0 me-2" *ngIf="!solicitations">
          <i class="fas fa-calendar pe-2" aria-hidden="true"></i> Calendário
        </a>
        <a routerLink="/demands/new" class="btn btn-xs btn-primary mb-0" *ngIf="!solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Nova demanda
        </a>
        <a class="btn btn-xs btn-primary mb-0" (click)="copyToClipboard()" *ngIf="solicitations">
          <i class="fas fa-plus pe-2" aria-hidden="true"></i> Compartilhar
        </a>
      </div> -->
    </div>
  </div>
  <div class="card-body p-3 pb-4">  

    <ngx-skeleton-loader 
      *ngIf="skeleton"
      count="5" 
      appearance="line" 
      [theme]="{height: '45px'}">
    </ngx-skeleton-loader>
    
    <div class="table-responsive p-0" *ngIf="!skeleton && demands">
        <table class="table align-items-center mb-0" *ngIf="demands?.data?.length > 0 && context === 'demand'">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Demanda</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prazo</th>
              <!-- <th></th> -->
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Envolvidos</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <!-- <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let d of demands.data" class="pointer">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm py-2 pointer demand-name" (click)="showDemand(d.id, content)">
                      <!-- <span class="tag-demand me-2 text-primary criticality">
                        <i class="fa fa-bookmark" [class]="'_' + d.criticality" aria-hidden="true"></i>
                      </span> -->
                      <ng-container *ngIf="d.project?.code && d?.item_id">
                        {{d.project?.code}}.{{d?.item_id}}
                      </ng-container> 
                      {{ d.name }}
                      <ng-container *ngIf="!['requester'].includes(currentUser.group.name)">
                        <span class="emoji ms-3" *ngIf="d.emoji_id">{{getEmoji(d.emoji_id)}}</span>
                      </ng-container>
                    </h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ d.deadline | date: "dd/MM/yyyy" }}</span>
              </td>
              <!-- <td class="align-middle text-center text-sm">
                <span class="badge badge-sm bg-gradient-danger" *ngIf="d.priority">Prioridade</span>
              </td> -->
              <td class="align-middle text-center text-sm">
                <div class="avatar-group ms-auto">
                  <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                    <img [alt]="u?.name" [title]="u?.name" [src]="u?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'">
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-status" [style.background]="d.status.color">
                  {{ d.status.name }}
                </span>
              </td>
              <td class="align-middle text-center d-none">
                <button class="btn btn-link text-secondary mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 && context === 'task'">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <div class="table-responsive p-0" *ngIf="!skeleton && demandsTasks">
        <table class="table align-items-center mb-0" *ngIf="demandsTasks?.data?.length > 0 && context === 'task'">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Demanda</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tarefa</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Prazo</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let demandTask of demandsTasks.data" class="pointer">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm py-2 pointer demand-name" (click)="showDemand(demandTask.demand.id, content)">
                      <!-- <span class="tag-demand me-2 text-primary criticality">
                        <i class="fa fa-bookmark" [class]="'_' + demandTask.criticality" aria-hidden="true"></i>
                      </span> -->
                      <ng-container *ngIf="demandTask.demand.project?.code && demandTask.demand?.item_id">
                        {{demandTask.demand.project?.code}}.{{demandTask.demand?.item_id}}
                      </ng-container> 
                     {{ demandTask.demand.name }}
                      <span class="emoji ms-3" *ngIf="demandTask.demand.emoji_id">{{getEmoji(demandTask.demand.emoji_id)}}</span>
                    </h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{ demandTask.task.name }}</span>
              </td>
              <td class="align-middle text-center">
                <span class="text-xs font-weight-bold" [class.text-secondary]="!demandTask.expired" [class.text-danger]="demandTask.expired">{{ demandTask.due_date | date: "dd/MM/yyyy" }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="badge badge-status" [style.background]="demandTask.status.color">
                  {{ demandTask.status.name }}
                </span>
              </td>
              <td class="align-middle text-center d-none">
                <button class="btn btn-link text-secondary mb-0">
                  <i class="fa fa-ellipsis-v text-xs" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center d-block py-5" *ngIf="demands?.data?.length == 0 || demandsTasks?.data?.length == 0">
          <small>{{ emptyTableText }}</small>
          <div class="d-flex justify-content-center">
            <ng-lottie [options]="options" height="300px" (animationCreated)="animationCreated($event)"></ng-lottie>
          </div>
        </div>
    </div>
    <nav aria-label="Page navigation example" *ngIf="demands.last_page > 1 && !limit">
        <ul class="pagination justify-content-end mt-4">
            <li class="page-item active" *ngFor="let p of pagination"
                [class.active]="demands.current_page == p">
                <a class="page-link" (click)="goPage(p)" href="javascript:;">{{ p }}</a>
            </li>
        </ul>
    </nav>
  </div>
</div>

<ng-template #content let-modal>
  <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" (updateCalendar)="getDemands()" [modal]="true"></app-demands-form>
</ng-template>

<ng-template #create_demand let-modal>
  <app-requester-demands-form (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>