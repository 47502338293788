import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  protected apiUrl = environment.URL_API;

  constructor(protected http: HttpClient) {}

  protected get<T>(url: string) {
    return this.http.get<T>(`${this.apiUrl}/${url}`);
  }

  protected post<T>(url: string, body: any) {
    return this.http.post<T>(`${this.apiUrl}/${url}`, body);
  }

  protected delete<T>(url: string) {
    return this.http.delete<T>(`${this.apiUrl}/${url}`);
  }
}
