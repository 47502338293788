<div class="row align-items-center my-4">
    <div class="col-2">
        <select class="form-select" aria-label="form-select-lg" [(ngModel)]="status">
            <option value="">Status</option>
            <option *ngFor="let s of statusList" [value]="s.id">{{ s.name }}</option>
        </select>
    </div>
    <div class="col-2">
        <select class="form-select" aria-label="form-select-lg" [(ngModel)]="manager">
            <option value="">Responsável</option>
            <option *ngFor="let m of users" [value]="m.id">{{ m.name }}</option>
        </select>
    </div>
    <div class="col-2"><input type="date" class="form-control" [(ngModel)]="start"></div>
    <div class="col-2"><input type="date" class="form-control" [(ngModel)]="end"></div>
    <div class="col-2"><button class="btn btn-success w-100 mb-0" (click)="filter()"><i class="fas fa-filter me-2"></i> Filtar</button></div>
    <div class="col-2">
        <button class="btn btn-dark w-100 mb-0" 
            (click)="export()">
                <i class="fas fa-file-excel me-2"></i> 
                {{ exportLoading ? 'Gerando arquivo...' : 'Exportar' }}
        </button>
    </div>
</div>

<div class="row align-items-center text-center" *ngIf="total">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Demandas</p>
                    <h5 class="font-weight-bolder">{{ qtdDemands | number }}</h5>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <div class="numbers">
                    <p class="text-sm mb-0 text-uppercase font-weight-bold">Peças</p>
                    <h5 class="font-weight-bolder">{{ qtdParts | number }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col-lg-12 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton" 
            count="1" 
            appearance="line"
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
        <div class="card" *ngIf="!skeleton && chartPartsOptions">
            <div class="card-header pb-0 pt-3 bg-transparent">
                <h6 class="text-capitalize mb-0">Peças</h6>
                <p class="text-xs text-secondary mb-0">Quantidade de peças utilizadas</p>
            </div>
            <div class="card-body p-3" *ngIf="chartPartsOptions != null">
                <apx-chart
                    [series]="chartPartsOptions.series"
                    [chart]="chartPartsOptions.chart"
                    [dataLabels]="chartPartsOptions.dataLabels"
                    [plotOptions]="chartPartsOptions.plotOptions"
                    [theme]="chartPartsOptions.theme" 
                    [noData]="chartPartsOptions.noData"
                    [grid]="chartPartsOptions.grid"
                    [legend]="chartPartsOptions.legend" 
                    [xaxis]="chartPartsOptions.xaxis"
                ></apx-chart>
            </div>
        </div>
    </div>
    <div class="col-lg-6 mb-4">
        <ngx-skeleton-loader 
            *ngIf="skeleton" 
            count="1" 
            appearance="line"
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
        <div class="card z-index-2 h-100" *ngIf="!skeleton">
            <div class="card-header pb-0 pt-3 bg-transparent">
                <h6 class="text-capitalize mb-0">Status</h6>
                <p class="text-xs text-secondary mb-0">Porcentagem de demandas</p>
            </div>
            <div class="card-body p-3" *ngIf="chartOptions != null">
                <apx-chart 
                    [series]="chartOptions.series" 
                    [chart]="chartOptions.chart" 
                    [labels]="chartOptions.labels"
                    [noData]="chartOptions.noData"
                    [fill]="chartOptions.fill"
                    [colors]="chartOptions.colors">
                </apx-chart>
            </div>
        </div>
    </div>
    <div class="col-lg-6 mb-4" *ngIf="skeleton">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-6 mb-4" *ngFor="let pie of data">
        <app-box-pie-chart 
            [title]="pie.name" 
            [data]="pie.metrics">
        </app-box-pie-chart>
    </div>
</div>
<!-- <div class="row">
    <div class="col-lg-12 mb-4" *ngIf="skeleton">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>
    <div class="col-lg-12 mb-4" *ngFor="let bar of data">
        <app-box-bar-chart 
            [title]="bar.name" 
            [data]="bar.metrics">
        </app-box-bar-chart>
    </div>
</div> -->
<!-- <div class="row">
    <div class="col-lg-12 mb-4" *ngIf="skeleton">
        <ngx-skeleton-loader 
            count="1" 
            appearance="line" 
            [theme]="{height: '450px', marginBottom: '10px', borderRadius: '1rem'}">
        </ngx-skeleton-loader>
    </div>

</div> -->