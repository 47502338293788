import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { URL_API } from '../core/shared/services/api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, map } from 'rxjs';
import { ClientsService } from '../shared/services/user-management/clients.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token: any
  private redirectUrl: string = '/';

  userLoggedIn = new EventEmitter()
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' })

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private clientService: ClientsService
  ) {
    this.token = localStorage.getItem('access_token');
  }

  login(credentials: any) {
    const url = `${URL_API}/auth/login`;
    return this.http.post(url, JSON.stringify(credentials), { headers: this.headers }).pipe(
      map((response: any) => {

        const token = response.token;
        const user  = response.user;
        const roles = response.roles;

        if (token && user) {
          this.handleLoginSuccess(token, user, roles)
          return true;
        }
        return false;
      }));
  }

  private handleLoginSuccess(token: string, user: any, roles: any) {
    this.token = token;
    this.redirectUrl = user.group.name;
    this.clientService.setClientNavigation(String(user.client_id));
    // localStorage.setItem('user', JSON.stringify({ id: user.id, name: user.name, email: user.email, area_id: user.area_id, group: user.group }));
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('access_token', token);
    localStorage.setItem('roles', JSON.stringify(roles));
    this.userLoggedIn.emit();
  }

  getToken() {
    return this.token;
  }

  loggedIn() {
    if (this.getToken()) {
      return !this.jwtHelper.isTokenExpired();
    }
    return false;
  }

  logout() {
    this.token = null;
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
  }

  public setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }

  public getRedirectUrl(): string {
    return this.redirectUrl;
  }

}
