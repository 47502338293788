<div class="container-fluid px-0">
    <div class="row" *ngIf="areas">
        <div class="col-12" *ngIf="areas != null">
            <label *ngIf="type === 'demand'" [ngStyle]="{'color': styles.labelColor}">Selecione o tipo da demanda <span class="text-danger">*</span></label>
            <label *ngIf="type === 'user'" [ngStyle]="{'color': styles.labelColor}">Selecione a área do usuário <span class="text-danger">*</span></label>
            <div class="form-group mb-0">
                <!-- <label class="text-white">*Área</label> -->
                <ng-select 
                [items]="areas" 
                bindLabel="name" 
                bindValue="id"
                [searchable]="false"
                [clearable]="false"
                [(ngModel)]="area_id"
                placeholder="Selecione a sua área"
                (change)="handleArea($event)">
                </ng-select>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let field of fields; let parentIndex = index">
        <div class="row pt-0" *ngIf="(!field.area_id || field.area_id === area_id) && hasPermission(field)">
            <!-- <div [class]="type === 'demand' && !field.multiple ? 'col-6' : 'col-12'"> -->
            <div class="col-12">
                <div class="form-group mb-0" [ngClass]="type === 'demand' ? 'mt-4' : 'mt-2'">
                    <label [ngStyle]="{'color': styles.labelColor}">{{ field?.main_label }} <span class="text-danger" *ngIf="field.required">*</span></label>
                    <label class="fw-normal d-block" *ngIf="field.sub_label">{{ field?.sub_label }}</label>

                    <ng-container [ngSwitch]="field.input">
                        <!-- checkbox/radio -->
                        <div *ngSwitchCase="'checkbox'">
                            <!-- <div class="form-check mb-1" *ngFor="let item of field.values; let childIndex = index">
                            <input 
                            class="form-check-input" 
                            (change)="onCheckboxFieldValueChange(field, item.id, $event)" 
                            [type]="field.multiple ? 'checkbox' : 'radio'" 
                            name="field-{{parentIndex}}" 
                            id="field-{{parentIndex}}-options-{{childIndex}}">
                            <label 
                            class="fw-normal" 
                            role="button" 
                            for="field-{{parentIndex}}-options-{{childIndex}}">
                            {{ item.value }}
                            </label>
                        </div> -->
                        </div>
                        <!-- select -->
                        <ng-container *ngSwitchCase="'select'">
                            <ng-select 
                            [items]="field.values" 
                            [multiple]="field.multiple" 
                            bindValue="id"
                            bindLabel="value"
                            [(ngModel)]="field.selecteds" 
                            [ngModelOptions]="{standalone:true}"
                            [closeOnSelect]="!field.multiple" 
                            [placeholder]="field.main_label"
                            (change)="onSelectFieldValueChange(field, $event)">
                        </ng-select>
                        <!-- <p>{{ field.selecteds | json}}</p> -->
                        <small class="mt-2 field-filled-error" *ngIf="field?.filled === false && clickSubmit">Este campo é obrigatório.</small>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Render recursive children -->
                <ng-container *ngIf="field.selecteds && field.children">
                    <ng-container
                        *ngTemplateOutlet="renderRecursive; context: { $implicit: field.children, parent: field }"></ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #renderRecursive let-children let-parent="parent">
    <div class="form-group mb-0" [ngClass]="type === 'demand' ? 'mt-4' : 'mt-2'" *ngFor="let child of children">
        <ng-container *ngIf="shouldDisplayChild(child, parent)">
            <label [ngStyle]="{'color': styles.labelColor}">{{ child?.main_label }} <span class="text-danger" *ngIf="child.required">*</span></label>
            <ng-select 
            [items]="child.filteredValues"
            bindValue="id"
            bindLabel="value" 
            [(ngModel)]="child.selecteds"
            [ngModelOptions]="{standalone:true}"
             [multiple]="child.multiple" 
             [closeOnSelect]="!parent.multiple"
            (change)="onSelectFieldValueChange(child, $event)" 
            [placeholder]="child.main_label">
            </ng-select>
            <!-- <p>{{ child.selecteds | json}}</p> -->
            <small class="mt-2 field-filled-error" *ngIf="child?.filled === false && clickSubmit">Este campo é obrigatório.</small>

            <!-- Recursive call -->
            <ng-container *ngIf="child.selecteds && child.children">
                <ng-container
                    *ngTemplateOutlet="renderRecursive; context: { $implicit: child.children, parent: child }"></ng-container>
            </ng-container>
        </ng-container>
    </div>
</ng-template>