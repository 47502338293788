import { Component, Input, OnInit } from '@angular/core';
import { HistoryService } from '../../services/history.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  skeleton = true;
  @Input() demandId!: number;
  activities:any;

  constructor(private demandService: HistoryService) {}

  async ngOnInit() {
    this.getActivities();
  }

  getActivities() { 
    this.demandService.getByParentType({
      type: 'demand',
      id: this.demandId
    }).subscribe({
      next: (result:any) => {
        console.log(result)
        this.activities = result
        this.skeleton = false;
      },
      error: (err:any) => {
        console.error('Erro ao carregar o histórico da demanda', err);
      }
    });
  }

}
