import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'gestor-carrefour';
  localUser:any;

  ngOnInit(): void {
    // this.localUser = JSON.parse(localStorage.getItem('user'));
    // console.log(this.localUser);
  }

  isUserLoggedIn(): boolean {
    return !!localStorage.getItem('user'); 
  }
}
