import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService {

  getAll() {
    return this.get(`tasks`)
  }
  
  getThemes() {
    return this.get(`tasks/themes/all`)
  }

  getTasksStatusCount() {
    return this.get('tasks/status/count');
  }

  deleteByTaskId(id: number) {
    return this.delete(`task/${id}`)
  }

  getKanban() {
    return this.get(`tasks/kanban`)
  }

  updateStatus(data: any) {
    return this.post(`demands/tasks/status`, data)
  }

  setNewOrder(data: any) {
    return this.post(`tasks/kanban/order`, data)
  }

  setHighlight(data: any) {
    return this.post(`status/set/highlight`, data)
  }

}
