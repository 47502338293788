import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function confirmPasswordValidator(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const passwordControl = formGroup.get(passwordControlName);
    const confirmPasswordControl = formGroup.get(confirmPasswordControlName);

    // Se o campo "Senha" estiver preenchido e o campo "Confirmar Senha" não, torna "Confirmar Senha" obrigatório
    if (passwordControl?.value && confirmPasswordControl?.value === '') {
      confirmPasswordControl?.setValidators([Validators.required]);
    } else {
      confirmPasswordControl?.setValidators(null);
    }

    // Se ambos os campos estão preenchidos, verifica se as senhas coincidem
    if (passwordControl?.value !== confirmPasswordControl?.value) {
      return { passwordMismatch: true };
    }

    // Se as senhas coincidirem e "Confirmar Senha" for obrigatório, retorna null
    return null;
  };
}