import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-box-kanban',
  templateUrl: './box-kanban.component.html',
  styleUrls: ['./box-kanban.component.scss']
})
export class BoxKanbanComponent implements OnInit {

  @Input() context = 'demand'
  @Input() openDemandPopup = true;
  @Output() updateBoxStatus = new EventEmitter()

  users:any

  //filters 
  user_id:any = ''
  deadline:any = ''
  area_id:any = ''
  status_id:any = ''

  status: any = []
  skeleton = true

  demand_id: any = 0

  header: any

  constructor(
    private demandService: DemandsService,
    private statusService: StatusService,
    private userService: UserService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getUsers();
    this.getKanban()
    // this.getStatus();
  }

  getStatus() { 

    let context = this.context;
    if(this.context == 'tasktype') context = 'task';
      

    this.statusService.getAll({
      context: context
    }).subscribe({
      next: (result) => this.status = result,
      error: (err) => console.log(err)
    })
  }

  getUsers() { 
    this.userService.getByGroup('analyst').subscribe({
      next: (result) => this.users = result,
      error: (err) => console.log(err)
    })
  }

  getKanban() {
    let filter = { 
      deadline: this.deadline,
      area_id:  this.area_id,
      user_id:  this.user_id
    }

    console.log(filter);

    this.statusService.getKanban(this.context, filter).subscribe({
      next: (result) => { 
        console.log(result);
        this.status = result
        this.skeleton = false
      },
      error: (err) => console.log(err)
    })
  }

  getEmoji(emoji_id:number) {
    if (!emoji_id) return null;
    let emoji = emojis.filter((e:any) => {
      return e.id == emoji_id
    })[0]
    return emoji.emoji
  }

  blockColumnTransfer(drag: CdkDrag<any>, drop: CdkDropList<any>): boolean {
    // Se o `drop` e o `drag` forem da mesma coluna, permita o arraste
    // return drag.dropContainer === drop;
    return false;
  }

  drop(event: CdkDragDrop<string[]>, status: any) {
    return;

    console.log(status)
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      this.demandService.updateStatus({ id: event.item.data.id, status_id: status.id, context: status.context }).subscribe({
        next:  () => this.updateBoxStatus.emit(true),
        error: (err) => console.log(err)
      })
    }
  }

  onColumnDrop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.status, event.previousIndex, event.currentIndex);
    const ids = this.status.map((item: any) => item.id);
    this.statusService.setNewOrder({ array_ids: ids }).subscribe({
      next: () => {},
      error: (err) => console.log(err)
    })
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  demandHeader(data: any) {
    this.header = data
  }

}
