<div class="emoji-selector">
    <button class="btn-emoji-text m-0 px-3" (click)="toggleEmojiList()" *ngIf="!selected">
        Classifique a demanda <i class="bi bi-chevron-down ms-4"></i>
    </button>
    <button class="btn btn-emoji m-0 px-1 py-0" (click)="toggleEmojiList()" *ngIf="selected">
        {{ selected.emoji }} 
    </button>
    <div class="emoji-list" *ngIf="show">
      <div *ngFor="let emoji of emojis" class="emoji-item" (click)="select(emoji)">
        {{ emoji.emoji }}
      </div>
    </div>
</div>