<div class="attachment-list w-100" *ngIf="style == 2 && files">
  <ul class="list-group">
      <li class="list-group-item d-flex align-items-center bg-white" *ngFor="let file of files">
      <img src="../../../../assets/argon-dashboard-master/assets/img/claro/file_extensions/{{getFileExtension(file.name)}}.svg" width="40">
      <div class="file-info">
          <a [href]="file.src" target="_blank"><h6>{{file.name}}</h6></a>
          <p>{{file?.user?.name}} em {{file?.created_at | date:'dd/MM/yyyy'}}, às {{file?.created_at | date:'HH\'h\'mm'}}</p>
      </div>
      </li>
</ul>
</div>

<ngx-file-drop
  dropZoneLabel="Drop files here"
  (onFileDrop)="dropped($event)"
  (onFileOver)="fileOver($event)"
  (onFileLeave)="fileLeave($event)"
  [multiple]="true"
  class="file-style-{{style}}"
>

  <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector"
  >
  <div class="d-flex justify-content-center align-items-center flex-column w-100 h-100" (click)="openFileSelector()" role="button" *ngIf="style == 1">
      <button type="button" class="d-block bg-transparent border-0"><img src="../../../../assets/argon-dashboard-master/assets/img/claro/icon-file-upload.svg" alt="" /> </button>
      <p class="text-center mb-0 mt-3">+ Adicionar novo arquivo</p>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-row w-100" *ngIf="style == 2">
      <button type="button" class="border-0 bg-transparent"><img src="../../../../assets/argon-dashboard-master/assets/img/claro/icon-file-upload-2.svg" alt="" width="23"/> </button>
      <p class="text-center m-0" *ngIf="!loading">Arraste seus arquivos ou <span (click)="openFileSelector()" role="button">procure em seu computador</span></p>
      <p class="text-center m-0" *ngIf="loading">Fazendo upload de arquivos...</p>
    </div>
    <div class="d-flex justify-content-center align-items-center flex-row w-100 position-relative overflow-hidden" *ngIf="style == 3"  (click)="openFileSelector()">
      <div class="avatar-photopicker-overlay" [ngStyle]="{'opacity': loading ? 1 : 0}">
        <i class="fa-solid fa-camera mb-2" *ngIf="!loading"></i>
        <span class="spinner-grow spinner-grow-sm" 
            *ngIf="loading" role="status" 
            aria-hidden="true">
        </span>
      </div>
      <img [src]="files[0] ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar rounded-circle me-3">
    </div>
  </ng-template>
</ngx-file-drop>

<div class="upload-table" *ngIf="style == 1 && files">
  <table class="table">
    <tbody class="upload-name-style">
      <tr *ngFor="let item of files; let i = index">
        <td>
          <small class="mb-0">{{ item.relativePath }}</small>
        </td>
      </tr>
    </tbody>
  </table>
</div>