
<div class="modal-demand-form" #demandModal>
    <div class="container-fluid" *ngIf="skeleton">
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center">
                <ngx-skeleton-loader count="1" appearance="circle"
                [theme]="{height: '50px', width: '50px', marginBottom: '5px'}">
                </ngx-skeleton-loader>
                <div class="d-flex flex-column ms-3">
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '22px', width: '220px', marginBottom: '5px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '15px', width: '160px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
            <!-- <div class="col-12 py-3">
                <ngx-skeleton-loader count="3" appearance="line"
                [theme]="{height: '30px', marginBottom: '10px', borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
            <div class="col-4">
                <ngx-skeleton-loader count="1" appearance="line"
                [theme]="{height: '40px', marginBottom: '10px'}">
                </ngx-skeleton-loader>
            </div> -->
            <div class="col-12 mt-4 mb-4">
                <div class="d-flex flex-column ms-3">
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '24px', width: '220px', marginBottom: '5px'}">
                    </ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" appearance="line"
                    [theme]="{height: '17px', width: '160px'}">
                    </ngx-skeleton-loader>
                </div>
            </div>
            <div class="col-4" *ngFor="let s of [1,2,3,4,5,6,7,8,9]">
                <ngx-skeleton-loader count="1" appearance="line"
                [theme]="{height: '44px', marginBottom: '10px'}">
                </ngx-skeleton-loader>
            </div>
        </div>
    </div>
    <div class="modal-header py-0 px-5" *ngIf="!skeleton">
        <!-- <span class="tag-demand me-3 criticality">
            <i class="fa fa-bookmark" [class]="'_' + demand?.criticality" aria-hidden="true"></i>
        </span> -->
        <h6 class="modal-title mb-0 demand-name">
            <ng-container *ngIf="demand.project?.code && demand?.item_id">
                {{demand.project?.code}}.{{demand?.item_id}}
            </ng-container> 
            {{ demand?.name }}
        </h6>
        <ng-container *ngIf="!['requester'].includes(currentUser.group.name)">
            <div class="emoji ms-3 me-auto bg-transparent">{{demand?.emoji?.emoji}}</div>
        </ng-container>
        <div class="view">
            <div class="row">
                <div class="col-9 d-flex align-items-center">
                    <!-- <span class="timesheet d-flex me-3 text-white" *ngIf="showTimesheet && demand.timesheet_sum" role="button" (click)="startTimesheet()">
                        <img src="../../../../assets/argon-dashboard-master/assets/img/claro/timesheet.svg" alt="timesheet icon" width="20" class="me-2">
                        {{demand?.timesheet_sum ?? '00:00'}}
                    </span> -->
                    <span class="text-white fw-bold ms-auto text-xs d-flex align-items-center me-3" id="demand-deadline">
                        <i class="fa fa-calendar me-2" aria-hidden="true"></i>
                        {{ demand?.deadline | date: 'dd/MM/y' }}
                    </span>
                    <div class="badge py-2 px-4" [style.backgroundColor]="demand.status?.color">
                        {{ demand.status?.name }}
                    </div>
                </div>
                <!-- <div class="col-3">
                    <button type="button" *ngIf="showCloseButton" class="btn btn-sm btn-primary mb-0" (click)="cancel()" aria-label="Close">x</button>
                </div> -->
            </div>
        </div>
    </div>

    <div class="modal-body px-5 demand-content" *ngIf="!skeleton">
        <div class="row mt-3" *ngIf="showRequester">
            <!-- <hr class="horizontal dark mt-3 mb-0"> -->
            <div class="col-9 requester">
                <img class="me-3" [src]="demand.user?.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" alt="" [ngbTooltip]="demand?.user.name">
                <div>
                    <p class="mb-0">{{ demand.user?.name }}</p>
                    <p class="mb-0 text-xs">{{ demand.user?.email }} | {{ demand.user?.phone | mask: '(00) 00000-0000' }}</p>
                </div>
            </div>
            <div class="col-3 text-end" *ngIf="['screening'].includes(currentUser.group.name) && triggerArea.show">
                <button id="btn-action-area" class="btn btn-sm btn-primary mb-0" (click)="duplicateDemand()" [disabled]="triggerArea.disabled">ACIONAR ÁREA</button>
            </div>
        </div>
        <hr class="horizontal dark my-3">
        <div class="row mb-4 demand-area-title">
            <div class="col-12">
                <p class="m-0">Tipo da demanda</p>
                <p class="fw-bold text-uppercase m-0">{{demand.area.name}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-12 mx-auto">
                <div class="card card-body z-index-2 h-100 pt-0 shadow-none px-0">
                        <div class="row">
                            <ng-container *ngFor="let f of fields">
                                <app-field-recursive
                                  [field]="f"
                                  [demand_id]="demand_id"
                                  [area_id]="demand.area_id"
                                  [disableFields]="['requester'].includes(currentUser.group.name)"
                                  class="mb-3 d-flex"
                                  [ngClass]="f.children.length <= 0 ? childFieldSize : ''"
                                  *ngIf="!f.area_id || f.area_id && f.area_id == demand.area_id && (f?.user_group_id?.length <= 0)"
                                  >
                                </app-field-recursive>
                            </ng-container>
                         </div>
                         
                        <div class="row" *ngIf="['screening'].includes(currentUser.group.name)">
                            <div class="col-12">
                                <label class="form-label mb-1">Descrição da demanda</label>
                                <textarea name="" disabled id="requester_description" class="form-control border-0" [value]="demand.requester_description"></textarea>
                            </div>
                        </div>

                        <div class="row mt-3" *ngIf="['screening', 'leadership', 'analyst'].includes(currentUser.group.name)">
                            <div class="tasks col-6">
                                <app-tasks [demand_id]="demand_id" [max]="demand.deadline" (hasOpenTasks)="hasOpenTasks = $event"></app-tasks>
                            </div>
                            <div class="pill-menu col-6">
                                <ul class="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase active" id="pills-chat-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-chat" type="button" role="tab"
                                            aria-controls="pills-chat" aria-selected="true">Comentários</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button *ngIf="['analyst'].includes(currentUser.group.name)" class="nav-link fw-bold text-uppercase" id="pills-activities-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-activities" type="button"
                                            role="tab" aria-controls="pills-activities"
                                            aria-selected="false">Atividades</button>
                                    </li>
                                    <!-- <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase" id="pills-recurring-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-recurring" type="button" role="tab"
                                            aria-controls="pills-recurring" aria-selected="false">Recorrências</button>
                                    </li> -->
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase" id="pills-history-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-history" type="button" role="tab"
                                            aria-controls="pills-history" aria-selected="false">Histórico</button>
                                    </li>
                                </ul>
                                <div class="tab-content px-3" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" tabindex="0">
                                        <app-chat [demandId]="demand_id"></app-chat>
                                    </div>
                                    <div class="tab-pane fade" id="pills-activities" role="tabpanel" aria-labelledby="pills-activities-tab" tabindex="0">
                                       <app-events [demand_id]="demand_id"></app-events>
                                    </div>                                    
                                    <!-- <div class="tab-pane fade" id="pills-recurring" role="tabpanel" aria-labelledby="pills-recurring-tab" tabindex="0">
                                        ...
                                    </div> -->
                                    <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab" tabindex="0">
                                        <app-history [demandId]="demand_id" #history></app-history>
                                    </div>
                                    <!-- <div class="tab-pane fade" id="pills-timesheet" role="tabpanel" aria-labelledby="pills-timesheet-tab" tabindex="0" *ngIf="showTimesheet">
                                        ...
                                    </div> -->
                                </div>
                            </div>
                            <hr class="horizontal dark my-5">
                        </div>

                        <div class="row" *ngIf="currentUser.group.name !== 'requester'">
                            <div class="pill-menu">
                                <ul class="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase active" id="pills-briefing-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-briefing" type="button" role="tab"
                                            aria-controls="pills-briefing" aria-selected="true">Briefing</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link fw-bold text-uppercase attachment-title" id="pills-attachment-list-tab"
                                            data-bs-toggle="pill" data-bs-target="#pills-attachment-list" type="button"
                                            role="tab" aria-controls="pills-attachment-list"
                                            aria-selected="false">Anexos <span class="badge bg-danger">{{demand_files?.length}}</span></button>
                                    </li>
                                </ul>
                                <div class="tab-content px-3" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-briefing" role="tabpanel" aria-labelledby="pills-briefing-tab" tabindex="0">
                                        <ckeditor [editor]="Editor" [(ngModel)]="briefing" (blur)="updateBriefing()" (focus)="showMessageSaveBriefing = false"></ckeditor>
                                        <p *ngIf="showMessageSaveBriefing" class="alert alert-warning text-light font-weight-bold text-xs mt-1 py-1">Salvando conteúdo ...</p>
                                    </div>
                                    <div class="tab-pane fade" id="pills-attachment-list" role="tabpanel" aria-labelledby="pills-attachment-list-tab" tabindex="0">
                                        <app-file-upload [files]="demand_files" [context]="{context: 'demands', id: demand_id}" [style]="2"></app-file-upload>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer" *ngIf="!skeleton && currentUser.group.name !== 'requester'">
        <div class="row" *ngIf="showActionsButtons">
            <div class="col-12 text-end">
                <!-- <button class="btn-action btn-rating me-4" *ngIf="demand.status.identifier !== 'em_aprovacao'" (click)="evaluation()">Enviar avaliação</button> -->
                <button class="btn-action btn-finish" *ngIf="!hasOpenTasks && demand.status.identifier == 'em_aprovacao' && currentUser.group.name == 'screening'" (click)="finish()">Concluir demanda</button>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6">
                <button type="button" name="button" *ngIf="!modal" [disabled]="loading" routerLink="/demands"
                    class="btn btn-light m-0">
                    Voltar
                </button>
            </div>
            <div class="col-lg-6 col-12 mx-auto d-none">

                <div class="d-flex justify-content-end">
                    <button type="button" name="button" [disabled]="loading" (click)="cancel()" class="btn btn-light m-0">
                        {{ modal ? 'Fechar' : 'Cancelar' }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <app-requester-demands-form [demand_id]="demand_id" (close)="modal.dismiss('Cross click')"></app-requester-demands-form>
</ng-template>