import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user-management/user.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  currentUser:any

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
  }

}
