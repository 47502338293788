import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FilesService extends BaseService  {

  getByDemandId(demand_id: number) {
    return this.get(`demands/${demand_id}/files`);
  }

  save(formData: FormData, context: string, id: number) {
    return this.post(`${context}/${id}/files`, formData);
  }
}
