<div class="demand-events-container">
    <ul class="demand-events-list p-0 mt-3 list-unstyled" #eventsList>
        <li *ngFor="let e of events; let i = index" class="event-item ps-2 pe-3 py-2 mb-2" [class.new]="!e?.id">
            <div class="d-flex justify-content-start align-items-center">
                <img [src]="e.user.avatar ?? 'assets/argon-dashboard-master/assets/img/default-user.png'" class="avatar me-2" [alt]="e.user.name" [ngbTooltip]="e.user?.name">
                <small>{{ e.user.name }}</small>
                <small class="ms-auto" style="font-size: 11px"></small>
                <button class="btn border-0 shadow-none p-0 m-0 ms-3 bg-white" [ngbTooltip]="e?.rrule_description" placement="top" (click)="event = e; isValid(e) ? showCalendar() : ''">
                    <i class="fa-regular fa-calendar"></i>
                </button>
                <button class="btn border-0 shadow-none p-0 m-0 ms-3 bg-white" (click)="e?.id ? delete(e?.id) : deleteFromArray(i)">
                    <i class="fa-regular fa-circle-xmark ms-3 me-2 delete-event-btn"></i>
                </button>
            </div>
            <hr class="horizontal dark my-2">
            <div class="d-flex justify-content-between align-items-center">
                <input [(ngModel)]="e.name" [disabled]="!e.new" placeholder="Nome da atividade" class="form-control rounded-0 border-0 bg-white"/>
            </div>
            <hr class="horizontal dark my-2">
            <div>
                <input [(ngModel)]="e.description" [disabled]="!e.new" placeholder="Descrição da atividade" class="form-control rounded-0 border-0 bg-white" />
            </div>
        </li>
    </ul>
    <button class="btn-add" (click)="addEvent()">+ Adicionar atividade</button>
</div>

<ng-template #content let-modal>
    <app-event-calendar (save)="save($event); modal.dismiss('Cross click')" [event]="event" (cancelModal)="modal.dismiss('Cross click')"></app-event-calendar>
</ng-template> 
