import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import { Clipboard } from '@angular/cdk/clipboard';
import Swal from 'sweetalert2';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AreaService } from '../../services/user-management/area.service';

import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';
import { DemandTasksService } from '../../services/demands/demand-tasks.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-demand-table',
  templateUrl: './demand-table.component.html',
  styleUrls: ['./demand-table.component.scss']
})
export class DemandTableComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/argon-dashboard-master/assets/animation/animation_ll6pcbjg.json'
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  // filters
  query = ''
  order = 'desc'
  area_id:any = '';
  deadline:any = ''
  @Input() limit: any = null
  @Input() context:string = 'demand';
  @Input() solicitations:boolean = false
  @Input() defaultFilter: any = {};
 
  // config 
  @Input() title = 'DEMANDAS'
  @Input() sharedUrl: any
  @Input() emptyTableText = 'Nenhuma demanda no momento'
  @Input() addNew = false;
  skeleton = true
  page = 1
  pagination: Array<number> = []

  //demands
  demandsTasks: any = []
  demands: any = []
  demand_id: any = 0
  
  header: any
  client: any
  areas:any = '';

  currentUser:any = null;

  constructor(
    private service: DemandsService, 
    private demandTaskService: DemandTasksService,
    private areaService: AreaService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private userService: UserService,
    private clipboard: Clipboard
  ) { }

  ngOnInit(): void {
    this.currentUser = this.userService.getLocalUser();
    console.log(this.context)
    this.getItems();
    this.getFilters();
  }

  getFilters() { 
    this.getAreas()
  }

  getAreas() { 
    this.areaService.getAll().subscribe({
      next: (result) => this.areas = result,
      error: (err) => console.log(err)
    })
  }

  getDemands() {
    this.skeleton = true
    this.route.paramMap.subscribe(params => {
      let status = params.get('status')
      
      let filter = { 
        ...this.defaultFilter,
        status:   status, 
        limit:    this.limit, 
        query:    this.query,
        order:    this.order,
        page:     this.page,
        context:  this.context,
        deadline:     this.deadline,
        area_id:  this.area_id,
        solicitations: this.solicitations
      }

      this.service.getAll(filter).subscribe({
        next: (result: any) => {
          console.log('DEMANDAS', result)
          this.demands = result
          console.log(result)
          this.limit ? this.demands.data = this.demands.data.slice(0, this.limit) : ''
          this.skeleton = false
          if(this.pagination.length == 0) {
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
          }
        },
        error: err => console.log(err)
      })
    })
  }

  getDemandsTasks() {
    this.skeleton = true
    this.route.paramMap.subscribe(params => {
      let status = params.get('status')
      
      let filter = { 
        ...this.defaultFilter,
        status:   status, 
        limit:    this.limit, 
        query:    this.query,
        order:    this.order,
        page:     this.page,
        context:  this.context,
        deadline:     this.deadline,
        area_id:  this.area_id,
        solicitations: this.solicitations
      }

      this.demandTaskService.getAll(filter).subscribe({
        next: (result: any) => {
          console.log('DEMANDAS', result)
          this.demandsTasks = result
          console.log(result)
          this.limit ? this.demandsTasks.data = this.demandsTasks.data.slice(0, this.limit) : ''
          this.skeleton = false
          if(this.pagination.length == 0) {
            for (let i = 1; i <= result.last_page; i++) {
              this.pagination.push(i)
            }
          }
        },
        error: err => console.log(err)
      })
    })
  }

  getByAreaId(area_id: any) { 
    this.area_id = area_id
  }

  getByDate(event:any) {
    this.deadline = event.target.value;
    this.getItems();
  }

  searchItens(query: any) {
    this.query = query
    this.getItems()
  }

  orderItens(order: any) {
    this.order = order
    this.getItems()
  }

  goPage(p: number) {
    this.page = p
    this.getItems()
  }

  getItems() {
    switch(this.context) { 
      case 'demand':
        this.getDemands()
      break;
      case 'task':
        this.getDemandsTasks();
      break;
    }
  }

  copyToClipboard() {
    this.clipboard.copy(`${this.sharedUrl}`);
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      text: 'Copiado para a área de transfência',
      showConfirmButton: false,
      timer: 1500,
    });
  }

  showDemand(demand_id: number, content: any) {
    this.demand_id = demand_id
    this.modalService.open(content, { windowClass: 'custom-modal', size: 'xl' });
  }

  newDemand(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }

  demandHeader(data: any) {
    this.header = data
  }

  getEmoji(emoji_id:number) {
    if (!emoji_id) return null;
    let emoji = emojis.filter((e:any) => {
      return e.id == emoji_id
    })[0]
    return emoji.emoji
  }

}
