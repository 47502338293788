import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/shared/services/demands/projects.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit {

  filter: any = {};
  project: any = {};
  skeleton = false;

  constructor(
    private route: ActivatedRoute,
    private service: ProjectsService
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.filter.project_id = params.get('id');
      this.skeleton = true;

      this.service.getById(this.filter.project_id).subscribe({
        next: (result: any) => {
          console.log(result);
          this.project = result;
          this.skeleton = false;
        },
        error: (err) => console.log(err)
      })
    })

  }

}
