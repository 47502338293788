import { Component, Input, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @Input() title = 'Dashboard'

  currentUser: any
  clients:any = []
  selectedClient:any;

  constructor(
    private clientsService: ClientsService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

  this.currentUser = this.userService.getLocalUser();

   if(this.currentUser.group_id == 3){
      this.getClients()
   }
  }

  getClients(){
    this.clientsService.getAll().subscribe({
      next: (result: any) => {
        this.clients = result.data
      },
      error: (err) => console.log(err)
    })
  }

  setClientNavigation(id:any) {
    this.clientsService.setClientNavigation(id).subscribe({
      next: () => { 
        window.location.reload();
      },
      error: (err) => console.log(err)
    })
  }
}
