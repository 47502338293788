<div style="width: 27vw; max-width: 460px">
    <label class="form-label mb-1">{{ field.name }}</label>
    <ng-multiselect-dropdown
      [placeholder]="'Selecione os itens'"
      [settings]="field.dropdownSettings"
      [disabled]="isDisabled()"
      [data]="field.values"
      [ngModelOptions]="{ standalone: true }"
      [(ngModel)]="field.selecteds"
      (onSelect)="onItemSelect(field, $event)"
      (onDeSelect)="onItemDeSelect(field, $event)">
    </ng-multiselect-dropdown>
</div>
<ng-container *ngFor="let child of field.children">
  <ng-container *ngIf="isSelected(child, field)">
    <app-field-recursive
      [field]="child"
      [demand_id]="this.demand_id"
      [disableFields]="disableFields"
      *ngIf="!child.area_id || child.area_id && child.area_id == area_id"
      [ngStyle]="{'width': field.children ? 'auto' : '27vw'}"
      style="display: grid; grid-template-columns: repeat( auto-fit, minmax(250px, 1fr)); margin-left: 2vw;"
      >
    </app-field-recursive>
  </ng-container>
</ng-container>