<div class="collapse navbar-collapse w-auto" id="sidenav-collapse-main">
    <a class="navbar-brand m-0 mb-4" target="_blank">
        <img src="../../../../assets/argon-dashboard-master/assets/img/claro/claro.svg" class="d-block m-auto" alt="" style="filter: brightness(10)">
    </a>
    <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let m of menu; let i = index">
            
            <a class="nav-link" *ngIf="!m.sub" [routerLink]="m.routerLink" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i [class]="m.icon" class="text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">{{ m.name }}</span>
            </a>

            <a *ngIf="m.sub" data-bs-toggle="collapse" 
                [href]="'#drop_' + i" 
                [id]="'link_' + i"
                class="nav-link collapsed"
                [attr.aria-controls]="'drop_' + i"
                role="button" 
                aria-expanded="false">
                <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                    <i class="ni ni-chart-pie-35 text-warning text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">{{ m.name }}</span>
            </a>

            <div class="collapse" [id]="'drop_' + i">
                <ul class="nav ms-4">
                    <li class="nav-item" *ngFor="let s of m.sub">
                        <a class="nav-link" [routerLink]="s.routerLink" routerLinkActive="active">
                            <span class="sidenav-normal">{{ s.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="nav-item">
            <a class="nav-link pointer" (click)="logOut()">
                <div
                    class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                    <i class="ni ni-button-power text-danger text-sm opacity-10"></i>
                </div>
                <span class="nav-link-text ms-1">Sair</span>
            </a>
        </li>
    </ul>
    <hr class="horizontal dark mt-5">
    <small class="text-center text-white d-block pb-3">{{ version }}</small>
</div>
