import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseService  {

  getAll(filter: any = null) {
    return this.post(`projects/filter`, filter)
  }

  getById(id: number) {
    return this.get(`projects/${id}`)
  }

  save(data:any) { 
    return this.post(`projects`, data)
  }

}
