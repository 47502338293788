import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RRule } from 'rrule';
import { DemandEventsService } from '../../services/demands/demand-events.service';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit{

  @Input() demand_id:number;
  @Input() event:any;
  @Output() save           = new EventEmitter();
  @Output() cancelModal    = new EventEmitter();

  eventForm:any;
  show = false;

  translations = {
    'Start': 'Data de Início',
    'Start Date': 'Data de Início',
    'End': 'Data de Término',
    'Repeat every': 'Repetir a cada',
    'Year': 'Ano',
    'Week': 'Semana',
    'Month': 'Mês',
    'Day': 'Dia',
    
    // Meses completos
    'January': 'Janeiro',
    'February': 'Fevereiro',
    'March': 'Março',
    'April': 'Abril',
    'May': 'Maio',
    'June': 'Junho',
    'July': 'Julho',
    'August': 'Agosto',
    'September': 'Setembro',
    'October': 'Outubro',
    'November': 'Novembro',
    'December': 'Dezembro',
  
    // Meses abreviados
    'Jan': 'Jan',
    'Feb': 'Fev',
    'Mar': 'Mar',
    'Apr': 'Abr',
    'Jun': 'Jun',
    'Jul': 'Jul',
    'Aug': 'Ago',
    'Sep': 'Set',
    'Oct': 'Out',
    'Nov': 'Nov',
    'Dec': 'Dez',
    
    // Dias da semana
    'Sunday': 'Domingo',
    'Monday': 'Segunda-feira',
    'Tuesday': 'Terça-feira',
    'Wednesday': 'Quarta-feira',
    'Thursday': 'Quinta-feira',
    'Friday': 'Sexta-feira',
    'Saturday': 'Sábado',
    'Mon': 'Seg',
    'Tue': 'Ter',
    'Wed': 'Qua',
    'Thu': 'Qui',
    'Fri': 'Sex',
    'Sat': 'Sáb',
    'Sun': 'Dom',
    
    // Frequência (ordem)
    'First': 'Primeiro(a)',
    'Second': 'Segundo(a)',
    'Third': 'Terceiro(a)',
    'Fourth': 'Quarto(a)',
    'Last': 'Último(a)',
  
    // Outros exemplos de tradução para eventos de calendário
    'Repeat on': 'Repetir em',
    'Every': 'Todo',
    'Interval': 'Intervalo',
    'After': 'Após',
    'Before': 'Antes',
    'Once': 'Uma vez',
    'Never': 'Nunca',
    'Times': 'Vezes',
    'Until': 'Até',
    'Ends': 'Termina',
    'On'  : 'Em',
    'on'  : 'Em',
    'of'  : 'de',
    'on day' : 'No dia',
    'on the' : 'No/Na',
    'on date': 'Em data específica',
    'occurrences': 'ocorrências',
    'Weekday': 'Dia útil',
    'Weekend day': 'Dia de final de semana'
  };
  

  constructor (
    private formBuilder: FormBuilder,
    private demandEventsService: DemandEventsService,
    private renderer: Renderer2, 
    private el: ElementRef
  ) {}

  ngAfterViewInit() {
    console.log('evento');
    
    // Traduzindo elementos após a renderização inicial
    this.translateStrings();

    let repeatQuantityInputEl = this.el.nativeElement.querySelector('ngx-repeat > form > div.row.align-items-center > div:nth-child(2) > input');
    
    if (repeatQuantityInputEl) {
      this.renderer.setAttribute(repeatQuantityInputEl, 'type', 'number');
      this.renderer.setProperty(repeatQuantityInputEl, 'value', '1');
    }
  }

  ngOnInit(): void {
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    console.log('eventCalendar carregado');
    let rrule = this.event.rrule || '';
    console.log(rrule);
    this.eventForm = this.formBuilder.group({
      rrule: [rrule]
    });

    this.eventForm.valueChanges.subscribe(value => {
      let repeatQuantityInputEl = this.el.nativeElement.querySelector('ngx-repeat > form > div.row.align-items-center > div:nth-child(2) > input');
      let occurrencesQuantityInputEl = this.el.nativeElement.querySelector('ngx-end > form > div > div.col-auto.d-flex.align-items-center > div > div > input');
    
      if (repeatQuantityInputEl) {
        this.translateStrings();
        this.renderer.setAttribute(repeatQuantityInputEl, 'type', 'number');
        this.renderer.setAttribute(occurrencesQuantityInputEl, 'type', 'number');
      }
    })
  }

  translateStrings() {
    const labels = this.el.nativeElement.querySelectorAll('label');
    labels.forEach((label: HTMLElement) => {
      let translatedText = this.translations[label.textContent?.trim() || ''];
      if (!translatedText) {
        // Verifica se existe a chave com a primeira letra minúscula
        translatedText = this.translations[label.textContent?.trim().toLowerCase() || ''];
      }
      if (translatedText) {
        label.textContent = translatedText;
      }
    });
  
    const placeholders = this.el.nativeElement.querySelectorAll('[placeholder]');
    placeholders.forEach((input: HTMLInputElement) => {
      let translatedPlaceholder = this.translations[input.placeholder.trim()];
      if (!translatedPlaceholder) {
        // Verifica se existe a chave com a primeira letra minúscula
        translatedPlaceholder = this.translations[input.placeholder.trim().toLowerCase()];
      }
      if (translatedPlaceholder) {
        input.placeholder = translatedPlaceholder;
      }
    });
  
    const options = this.el.nativeElement.querySelectorAll('option');
    options.forEach((option: HTMLOptionElement) => {
      let translatedOption = this.translations[option.textContent?.trim() || ''];
      if (!translatedOption) {
        // Verifica se existe a chave com a primeira letra minúscula
        translatedOption = this.translations[option.textContent?.trim().toLowerCase() || ''];
      }
      if (translatedOption) {
        option.textContent = translatedOption;
      }
    });
  }
  

  onSubmit() {
    console.log(this.eventForm);
    if (this.eventForm.valid) {
      let rRuleFormValue: any = this.eventForm.value.rrule;
      
      let rRule = rRuleFormValue.rRule;
      
      if (rRule instanceof RRule) {
        const rruleString = rRule.toString();
        console.log(rruleString);
        this.event.rrule = rruleString;
        this.save.emit(this.event);
        this.eventForm.reset();
      }
    } else {
      console.log('Form inválido');
    }
  }

}
