import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class FieldService extends BaseService {

  getAll(filter: any = null) {
    return this.post(`fields/filter`, filter)
  }

  getById(id: number) {
    return this.get(`fields/${id}`)
  }

  updateStatus(data: any) {
    return this.post(`fields/status`, data)
  }

  save(data: FormData) {
    return this.post(`fields`, data)
  }

  update(data: FormData, id: number) {
    return this.post(`fields/${id}`, data)
  }

  deleteById(id: number) {
    return this.delete(`fields/${id}`)
  }

  saveFieldValue(data: FormData, field_id: number) {
    return this.post(`fields/${field_id}/values`, data)
  }

  deleteFieldValue(id: number) {
    return this.delete(`fields/values/${id}`)
  }

  updateFieldValue(data: any) {
    return this.post(`fields/values/${data.id}`, data)
  }

  getFieldsWithChildren() { 
    return this.get('fields/children/all');
  }

  getFieldsAndValuesByModel(model_id:number, model_type:string, filter:any) { 
    return this.post(`fields/values/model/${model_type}/${model_id}/filter`, filter)
  }
}
