import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { AuthService } from 'src/app/auth/auth.service';
import { ClientsService } from 'src/app/shared/services/user-management/clients.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {

  registerForm: any;
  pinForm: any;
  loading = false;
  user:any;
  pin:any = {
  };
  otpConfig:NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    autoblur: true,
    numericInputMode: true,
    classList: {
      container: 'custom-container',
      inputBox: 'custom-input-box',
      input: 'custom-input',
      inputFilled: 'custom-input-filled',
      inputSuccess: 'custom-input-success',
      inputError: 'custom-input-error'
    }
  };

  constructor(
    private formBuilder:    FormBuilder,
    private service:        UserService,
    private router:         Router,
    private auth:           AuthService,
    private clientsService: ClientsService,
    private route:          ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let hash = params.get('hashid');
      if (hash) this.getUserByHashId(hash);
    });
  }

  getUserByHashId(hash:any) {
    this.service.getByHashId(hash).subscribe({
      next: (result) => { 
        this.user = result;
        if (this.user.email_verified_at)
          this.router.navigateByUrl('login');
      }, 
      error: (err) => {
        console.log(err)
      }
    })
  }

  validatePin() {
    this.loading = true;
    this.service.validatePin({
      user_id: this.user.id,
      pin: this.pin.value
    }).subscribe({
      next: (result:any) => {
        console.log(result);
        this.pin.message = result.message;
        this.pin.valid = result.status;
        setTimeout(() => {
          this.router.navigateByUrl('login');
        }, 3000);
      },
      error: (err) => { 
        console.log(err) 
        this.loading = false;
        this.pin.valid = false;
        this.pin.message = err.error.message
      }
    })
  }

  onOtpChange(otp:any): void {
    let fullOtp = !otp.some((item:any) => item === null);
    if (fullOtp) 
      this.pin.value = otp.join('').toString();
    else 
      this.pin.value = null;
    console.log(this.pin);
  }

}
