<div class="card">
    <div class="card-body">

        <ngx-skeleton-loader 
            *ngIf="skeleton"
            count="1" 
            appearance="line" 
            [theme]="{height: '45px'}">
        </ngx-skeleton-loader>

        <div class="row ms-2 align-items-center" *ngIf="!skeleton">
            <div class="col-8"><span class="fs-5 text-uppercase"><span class="text-danger">{{project.id | number:'4.0'}}</span> {{project.name}} </span></div>
            <div class="col-4"><span class="fs-6 text-xs">
                <!-- <strong>Demandante:</strong> Silvio Lopes<br> -->
                <strong>Demanda criada em:</strong> 19/11/2024</span>
            </div>
        </div>
        <app-demand-table context="demand" [defaultFilter]="filter" title="DEMANDAS" [addNew]="true"></app-demand-table>
    </div>   
</div>

