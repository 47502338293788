import { Injectable } from '@angular/core';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly titleService: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    console.log("TITLE")
    console.log("TITLE")
    console.log("TITLE")
    const title = this.buildTitle(routerState);
    console.log(title);
    if (title !== undefined) {
      this.titleService.setTitle(`Aurora - ${title}`);
    } else {
      this.titleService.setTitle('Aurora');
    }
  }
}