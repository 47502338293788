import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DemandTasksService extends BaseService {
  
  getAll(filter:any = {}) { 
    return this.post(`demands/tasks/filter`, filter)
  }

  getById(demandTaskId:any) { 
    return this.get(`demands/tasks/${demandTaskId}`)
  }

  getByDemandId(demand_id: number) {
    return this.get(`demands/${demand_id}/tasks`)
  }

  save(demand_id:number, formData: FormData) {
    return this.post(`demands/${demand_id}/task`, formData)
  }

  done(data:any) {
    return this.post(`demands/tasks/done`, data)
  }

  deleteById(id: number) {
    return this.delete(`demand/task/${id}`)
  }
}
