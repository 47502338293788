<div class="card z-index-2 h-100 mt-4">
    <div class="card-header pb-2 pt-3 bg-transparent">
      <div class="row align-items-center table-filter">
        <div class="col-2">
          <h6 class="text-capitalize text-xs mb-0">PROJETOS</h6>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-4">  
  
      <ngx-skeleton-loader 
        *ngIf="skeleton"
        count="5" 
        appearance="line" 
        [theme]="{height: '45px'}">
      </ngx-skeleton-loader>
      
      <div class="table-responsive p-0" *ngIf="!skeleton">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Projeto</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total de demandas</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of projects.data" class="pointer">
                <td>
                    <div class="d-flex px-2 py-1">
                      <div class="d-flex flex-column justify-content-center">
                        <h6 class="mb-0 text-sm text-uppercase">
                          <a routerLink="/screening/projects/project/{{p.id}}">{{p.name}}</a>
                        </h6>
                      </div>
                    </div>
                </td>
                <td>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm"> 
                        <span>{{p.demands_count}}</span>
                      </h6>
                    </div>
                    
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center d-block py-5" *ngIf="projects?.data.length == 0">
            <small>Nenhum projeto encontrado!</small>
          </div>
      </div>
      <nav aria-label="Page navigation example" *ngIf="projects.last_page > 1 && !limit">
          <ul class="pagination justify-content-end mt-4">
              <li class="page-item active" *ngFor="let p of pagination"
                  [class.active]="projects.current_page == p">
                  <a class="page-link" href="javascript:;">{{ p }}</a>
              </li>
          </ul>
      </nav>
    </div>
  </div>