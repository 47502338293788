import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusService } from 'src/app/shared/services/demands/status.service';

@Component({
  selector: 'app-box-status',
  templateUrl: './box-status.component.html',
  styleUrls: ['./box-status.component.scss']
})
export class BoxStatusComponent implements OnInit {

  @Input()  metrics = false
  @Input()  context = 'demand'
  @Output() setStatus = new EventEmitter();

  status: any = []
  statusHighlight: any = []
  selected: any = null
  skeleton = true

  constructor(
    private service: StatusService,
    private route:   ActivatedRoute,
    private router:  Router
  ) { }

  ngOnInit(): void {
    this.getStatus()
  }

  getStatus(start = '', end = '', user_id = '') {
    this.route.paramMap.subscribe(params => {
      this.selected = params.get('status')
    })

    this.service.getAll({
      limit: 1000,
      context: this.context
    }).subscribe({
      next:  (result: any) => {
        this.status = result
        console.log(result)
        this.statusHighlight = result.filter((item: any) => item.highlight == true).slice(0, 3)
        this.skeleton = false
        console.log(result)
      },
      error: (err) => console.log(err)
    })
  }

  setHighlight(s: any) {
    this.service.setHighlight({
      id: s.id,
      highlight: !s.highlight
    }).subscribe({
      next: () => {
        this.getStatus()
      },
      error: (err) => console.log(err)
    })
  }

  filter(status: any) {
    if(!this.metrics) {
      this.router.navigateByUrl(`/demands/filter/${status}`) 
    } else {
      status = this.selected == status ? '' : status
      this.setStatus.emit(status)
      this.selected = status
    }  
  }
}
