import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: any
  loading = false

  constructor(
    private formBuilder: FormBuilder,
    private service:     AuthService,
    private router:      Router,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    if(localStorage.getItem('user')){
      localStorage.removeItem('user');
    }
    this.loginForm = this.formBuilder.group({
      'email'    : ['', Validators.required],
      'password' : ['', Validators.required]
    })
    console.log(this.router.url)
  }

  login(data: any) {
    this.loading = true
    this.service.login(data.value).subscribe({
      next:  (result) => {
        console.log(result);
        this.loading = false;
        this.router.navigate(['/']);
        console.log(this.auth.getRedirectUrl());
        this.router.navigateByUrl(this.auth.getRedirectUrl() || '/');
      },
      error: (err) => {
        console.log(err)
        this.loading = false;

        Swal.fire({
          icon: 'warning',
          text: err.error.message,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed && err.code == 400) {
            // this.router.navigateByUrl(`otp/verification/${this.user_hashid}`)
          }
        });
      }
    });
  }
}
