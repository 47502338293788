<div class="row g-3 align-items-center table-filter mb-3">
    <div class="col-2 d-flex justify-content-end">
        <input type="date" class="form-control" placeholder="Filtre por data" [(ngModel)]="deadline">
    </div>
    <div class="col-3 d-flex justify-content-end">
        <select name="" id="" class="form-control" [(ngModel)]="user_id" (change)="getKanban()">
            <option value="">Filtre por colaborador</option>
            <option [value]="u.id" *ngFor="let u of users">{{u.name}}</option>
        </select>
    </div>
    <!-- <div class="col-2 d-flex justify-content-end">
        <select name="" id="" class="form-control" [(ngModel)]="status_id" (change)="getKanban()">
            <option value="">Filtre por status</option>
            <option *ngFor="let s of status">{{s.name}}</option>
        </select>
    </div> -->
    <!-- <div class="col-2 d-flex justify-content-end">
        <select name="" id="" class="form-control" [(ngModel)]="" (change)="getKanban()">
            <option value="0">Filtre por etapa</option>
        </select>
    </div> -->
</div>
<div class="card">
    <div class="card-body">
        <div class="row" *ngIf="skeleton">
            <div class="col-lg-4">
                <ngx-skeleton-loader 
                    count="1" 
                    [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
            <div class="col-lg-4">
                <ngx-skeleton-loader 
                    count="1" 
                    [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
            <div class="col-lg-4">
                <ngx-skeleton-loader 
                    count="1" 
                    [theme]="{height: '400px', width: '100%',borderRadius: '1rem'}">
                </ngx-skeleton-loader>
            </div>
        </div>

        <div class="kanban-container" cdkDropListGroup *ngIf="!skeleton">
            <div class="row content g-3" cdkDropList (cdkDropListDropped)="onColumnDrop($event)" cdkDropListOrientation="horizontal" [cdkDropListData]="status" cdkDropListGroup>
                <div class="col-lg-4" *ngFor="let s of status">
                    <h6 class="text-center" [style.color]="s.color"><i *ngIf="s.icon" [class]="s.icon"></i> {{ s.name }}</h6>
                    <div class="card card-body p-3 bg-light shadow-none" cdkDrag [cdkDragData]="s">
                        <div cdkDropList
                            class="item-drop"
                            autoScrollDisabled="true"
                            [cdkDropListData]="s.demands"
                            (cdkDropListDropped)="drop($event, s)"
                            [cdkDropListEnterPredicate]="blockColumnTransfer">
                            <!-- <div cdkDrag [cdkDragData]="d" class="card pointer z-index-2 my-2 item"
                                [style.border]="s.color"
                                [style.background]="d.priority ? '#ff727254' : ''"
                                [routerLink]="'/demands/edit/' + d.id" *ngFor="let d of s.demands">
                                <div class="card-body p-3">
                                    <h6 class="text-capitalize mb-0 kanban-title demand-name">{{ d.name }}</h6>
                                    <p class="text-xs text-secondary mb-0">{{ d.date | date : 'dd/MM/yyyy' }}</p>
                                </div>
                            </div> -->
                            <div cdkDrag [cdkDragData]="d" class="card kanban-card pointer z-index-2 item"
                                [style.border]="s.color"
                                (click)="openDemandPopup ? showDemand(d.id, content) : ''" *ngFor="let d of s.demands">
                                <!-- <span class="tag-demand me-2 criticality">
                                    <i class="fa fa-bookmark" [class]="'_' + d?.criticality" aria-hidden="true"></i>
                                </span> -->
                                <div class="card-body p-3">
                                    <h6 class="text-capitalize mb-0 kanban-title demand-name">
                                        <ng-container *ngIf="d.project?.code && d?.item_id">
                                            {{d.project?.code}}.{{d?.item_id}}
                                        </ng-container> 
                                        {{ d.name }}
                                    </h6>
                                    <p class="text-xs text-secondary mb-0 py-2">
                                        <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {{ d.created_at | date : 'dd/MM/yyyy' }}
                                    </p>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="avatar-group">
                                            <div class="avatar avatar-lg avatar-sm rounded-circle" *ngFor="let u of d.involved_users">
                                                <img alt="Image placeholder" [title]="u.name" [ngbTooltip]="u.name" [src]="u.avatar || 'assets/argon-dashboard-master/assets/img/default-user.png'">
                                            </div>
                                        </div>
                                        <div class="text-end d-flex align-items-center">
                                            <div class="emoji me-2">{{getEmoji(d?.emoji_id)}}</div>
                                            <i class="fa fa-comments" aria-hidden="true"></i> <span class="text-xs ms-1">{{ d?.chats_count }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
    <app-demands-form #demand [demand_id]="demand_id" (demandHeader)="demandHeader($event)" (cancelModal)="modal.dismiss('Cross click')" [modal]="true"></app-demands-form>
</ng-template>