import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ManagersService extends BaseService {

  getAll(filter: any = null) {
    return this.http.post(`managers/filter`, filter)
  }

  getById(id: number) {
    return this.http.get(`managers/${id}`)
  }

  save(data: FormData) {
    return this.http.post(`managers`, data)
  }

  update(data: FormData, id: number) {
    return this.http.post(`managers/${id}`, data)
  }

  deleteById(id: number) {
    return this.http.delete(`managers/${id}`)
  }
}
