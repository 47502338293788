import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-page-default',
  templateUrl: './page-default.component.html',
  styleUrls: ['./page-default.component.scss']
})
export class PageDefaultComponent implements OnInit {

  @Input() title = 'Dashboard'
  @Input() modal = false
  @Input() menu: any = []

  constructor( 
    private router: Router,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    
    // Obtenha o título da rota
    this.updateTitle();
    
    // Escute as mudanças de navegação
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.updateTitle();  // Atualize o título a cada navegação
      });
  }

  // Função que encapsula a lógica de obtenção do título
  private updateTitle(): void {
    let child = this.activatedRoute.firstChild;
    while (child?.firstChild) {
      child = child.firstChild;
    }

    this.title = String(child?.snapshot.routeConfig?.title || '');
  }


}
