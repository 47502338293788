<div class="timesheet-card" [ngClass]="show ? 'show' : 'hide'" *ngIf="this.timer">
    <div class="close" role="button" (click)="show = !show"><i class="fa-solid fa-chevron-right"></i></div>
    <div class="form-group bg-white mb-0 p-3">
        <div class="d-flex">
            <span id="timer" [class.readonly]="stopped">{{timer || '00:00:00'}}</span>
            <button class="stop-button" role="button" id="stopButton" (click)="stop()" *ngIf="timer && !stopped">Parar</button>
        </div>
        <form [formGroup]="timesheetForm" class="d-flex mt-3" *ngIf="stopped">
            <input type="text" name="title"  id="title" formControlName="title" class="form-control" placeholder="Digite aqui...">
            <button class="save-button" role="button" id="saveButton" [disabled]="!timesheetForm.valid" (click)="save()">Salvar</button>
        </form>
        <label class="job-title mt-3 mb-0 demand-name">
            <ng-container *ngIf="timesheet.demand_task.demand.project?.code && timesheet.demand_task.demand?.item_id">
                {{timesheet.demand_task.demand.project?.code}}.{{timesheet.demand_task.demand?.item_id}}
            </ng-container> 
            {{' - ' + timesheet.demand_task.demand.name}}
        </label>
    </div>
</div>