import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService extends BaseService {
  
  getAll() {
    return this.get(`areas`)
  }
  
  save(data: FormData) {
    return this.post(`areas`, data)
  }

  getById(id: number) {
    return this.get(`areas/${id}`)
  }

  getByHash(hash: string) {
    return this.get(`areas/h/${hash}`)
  }

  deleteById(id: any) {
    return this.delete(`areas/${id}`)
  }
}
