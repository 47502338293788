<ngx-skeleton-loader 
*ngIf="skeleton" 
count="1" 
appearance="line"
[theme]="{height: '93px', marginBottom: '10px', borderRadius: '1rem'}">
</ngx-skeleton-loader>
<div class="box-status-detail-demand">
    <div class="row align-items-end">
        <div class="col-lg-{{floor(columns / status.length)}} box-status-detail-demand" *ngFor="let s of status">
            <div class="card">
                <div class="icon shadow"><img src="assets/argon-dashboard-master/assets/img/claro/icons/status/{{s?.icon}}" alt=""></div>
                <div class="card-header py-1 text-uppercase" [ngStyle]="{'background-color': s?.color}"><span>{{s?.name}}</span></div>
                <div class="card-body text-center py-3 shadow">
                    <h5 class="m-0" *ngIf="context === 'demand'">{{s.demands_count}}</h5>
                    <h5 class="m-0" *ngIf="context === 'task'">{{s.tasks_count}}</h5>
                </div>
            </div>
        </div>
    </div>
</div>