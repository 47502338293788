<main class="main-content main-content-bg login-page mt-0">
    <div class="page-header min-vh-100"
        style="background: url('../../../assets/argon-dashboard-master/assets/img/claro/bg-login.png'); background-size: cover;">
        <!-- <span class="mask bg-gradient-dark opacity-8"></span> -->
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-7 col-md-7">
                    <div class="card register border-0 mb-0 bg-transparent shadow-none">
                        <div class="card-header bg-transparent pb-0">
                            <!-- <h5 class="text-dark text-center mt-2">Álamo Gestor</h5> -->
                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/icon-user-login.svg"
                                width="" class="d-block m-auto" alt="">
                        </div>
                        <div class="card-body px-lg-5 pt-2">
                            <div class="text-center text-muted mb-5 mt-4">
                                <h3 class="text-white px-5">Comunicação, treinamento, processos, campanhas motivacionais e solar</h3>
                            </div>
                            <form [formGroup]="registerForm" class="text-start">
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                        <!-- <span class="input-group-text border-0 border-radius-0" id="basic-addon1">
                                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/user.svg" alt="">
                                        </span> -->
                                    </div>
                                    <input 
                                    type="text" class="form-control border-0 shadow-none ps-2"
                                    formControlName="name" 
                                    placeholder="Nome" 
                                    aria-label="Nome">
                                </div>
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                        <!-- <span class="input-group-text border-0 border-radius-0" id="basic-addon1">
                                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/user.svg" alt="">
                                        </span> -->
                                    </div>
                                    <input 
                                    type="email" 
                                    class="form-control border-0 shadow-none ps-2"
                                    formControlName="email"
                                    placeholder="Email"
                                    aria-label="Email">
                                </div>
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                        <!-- <span class="input-group-text border-0 border-radius-0" id="basic-addon1">
                                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/user.svg" alt="">
                                        </span> -->
                                    </div>
                                    <input 
                                    type="phone" 
                                    class="form-control border-0 shadow-none ps-2"
                                    formControlName="phone"
                                    placeholder="Telefone"
                                    aria-label="Telefone"
                                    mask="(00) 00000-0000">
                                </div>
                                <div class="input-group align-items-center mb-3 bg-white">
                                    <div class="input-group-prepend ps-3">
                                        <!-- <span class="input-group-text border-0 border-radius-0" id="basic-addon1">
                                            <img src="../../../assets/argon-dashboard-master/assets/img/claro/lock.svg" alt="">
                                        </span> -->
                                    </div>
                                    <input 
                                    type="password" 
                                    class="form-control border-0 shadow-none ps-2"
                                    formControlName="password" 
                                    placeholder="Senha" 
                                    aria-label="Password">
                                </div>
                                  <div class="col-12">
                                      <app-fields 
                                      *ngIf="fields"
                                       [fields]="fields"
                                       type="user"
                                       (changeArea)="setArea($event)"
                                       style="background: #fff"
                                       [styles]="{labelColor: '#fff'}"></app-fields>
                                  </div>

                                <div *ngIf="registerForm.get('password')"
                                    class="bg-white p-3 password-validators rounded mt-3">
                                    <ng-container *ngFor="let validator of [
                                            { key: 'hasUpperCase', text: 'A senha precisa de pelo menos uma letra maiúscula.' },
                                            { key: 'hasLowerCase', text: 'A senha precisa de pelo menos uma letra minúscula.' },
                                            { key: 'hasNumber', text: 'A senha precisa de pelo menos um número.' },
                                            { key: 'hasSpecialChar', text: 'A senha precisa de pelo menos um caractere especial.' },
                                            { key: 'isValidLength', text: 'A senha precisa ter pelo menos 8 caracteres.' }
                                        ]">
                                        <p class="mb-1"
                                            [ngClass]="registerForm.get('password').errors?.passwordComplexity?.[validator.key] || registerForm.get('password')?.valid ? 'text-success' : 'text-danger'">
                                            <i
                                                [ngClass]="registerForm.get('password').errors?.passwordComplexity?.[validator.key] || registerForm.get('password').valid ? 'fas fa-check' : 'fas fa-times'"></i>
                                            {{ validator.text }}
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="text-center d-flex align-items-centerx-auto my-4">
                                    <button type="submit" (click)="register(registerForm)"
                                        [disabled]="loading || !registerForm.valid" class="btn w-100 text-white mb-2">
                                        <span class="spinner-grow spinner-grow-sm" *ngIf="loading" role="status"
                                            aria-hidden="true">
                                        </span>
                                        {{ loading ? 'Cadastrando' : 'Cadastrar' }}
                                    </button>
                                    <a routerLink="/login"
                                        class="btn btn-secondary w-100 text-white ms-2 mb-2">Voltar</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>