<div class="event-calendar card px-0 mt-9">
    <div class="card-header py-3">
        <h5 class="text-center mb-0">Recorrência personalizada</h5>
    </div>
    <div class="card-body">
        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
            <ottomate-ngx-rrule formControlName="rrule"
            [hideStart]="false"
            [hideEnd]="false"
            tz="America/Sao_Paulo"
            [frequency]="['Daily','Monthly','Weekly', 'Yearly']">
            </ottomate-ngx-rrule>
            <p class="text-end mt-4 mb-0">
                <button type="submit" class="btn btn-primary mb-0">Salvar</button>
            </p>
        </form>
    </div>
</div>