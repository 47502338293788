import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class PartsService extends BaseService {

  getByDemandId(demand_id: number) {
    return this.get(`parts/demands/${demand_id}`)
  }

  getById(id: number) {
    return this.get(`parts/${id}`)
  }

  save(data: FormData, demand_id: number) {
    return this.post(`parts/demands/${demand_id}`, data)
  }

  update(data: FormData, id: number) {
    return this.post(`parts/${id}`, data)
  }

  deleteById(id: number) {
    return this.delete(`parts/${id}`)
  }

}
