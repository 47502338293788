import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import emojis from '../../../../assets/argon-dashboard-master/assets/img/claro/icons/emojis/emojis.json';

@Component({
  selector: 'app-emoji-selector',
  templateUrl: './emoji-selector.component.html',
  styleUrls: ['./emoji-selector.component.scss']
})
export class EmojiSelectorComponent implements OnInit {

  @Output() emojiSelected = new EventEmitter<number>();
  emojis:any;

  ngOnInit(): void {
    this.emojis = emojis;
  }

  selected:any = null;
  show: boolean = false;

  toggleEmojiList() {
    this.show = !this.show;
  }

  select(emoji: any) {
    this.selected = emoji;
    this.show = false; // Fechar a lista após a seleção
    this.emojiSelected.emit(this.selected.id);
  }

}
