import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/shared/services/demands/projects.service';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {

  skeleton = false;
  projects = {data:[],last_page:0,current_page:0};
  limit = 0;
  pagination = [];

  constructor(
    private service: ProjectsService
  ) { }

  ngOnInit(): void {

    this.service.getAll({}).subscribe({
      next: (result: any) => {
        this.projects = result
        this.skeleton = false
        /*if(this.pagination.length == 0) {
          for (let i = 1; i <= result.last_page; i++) {
            this.pagination.push(i)
          }
        }
        this.limit ? this.demands.data = this.demands.data.slice(0, this.limit) : ''
        */
        
      },
      error: err => console.log(err)
    })

  }

}
