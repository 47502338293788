import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService {

  // getAll(filter: any = null) {
  //   return this.get(`demands/chat`);
  // }

  getChatsByDemandID(demand_id: number) {
    return this.get(`demands/${demand_id}/chat`)
  }

  // updateStatus(data: any) {
  //   return this.post(`demands/${demand_id}/chat`, data)
  // }

  save(data: FormData) {
    return this.post(`demands/chat`, data)
  }

  getLastMessage() {
    return this.get(`demands/chat/last`)
  }

  // update(data: FormData, id: number) {
  //   return this.post(`demands/chat/${id}`, data)
  // }
}
