import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService extends BaseService {

  getByParentType(data:any) { 
    return this.post(`activities/logs`, data);
  }

  getHistory() { 
    return this.get(`logs`);
  }

}
