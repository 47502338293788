import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})

export class ClientsService extends BaseService {

  public client_id;

  constructor(http: HttpClient) {
    super(http);
    this.client_id = localStorage.getItem("client_id") || '';
  }

  save(data: FormData) {
    return this.post(`clients`, data)
  }

  getAll() {
    return this.get(`clients`)
  }

  getById(id: number) {
    return this.get(`clients/${id}`)
  }

  getByHash(hash: string) {
    return this.get(`clients/h/${hash}`)
  }

  deleteById(id: any) {
    return this.delete(`clients/${id}`)
  }

  getClientNavigation() {
    return this.get(`clients/select/logged`);
  }

  setClientNavigation(id: any) {
    this.client_id = id;
    console.log(this.client_id);
    return this.post(`clients/select`, {site_id: this.client_id})
  }
}
