import { Component, Input } from '@angular/core';
import { DemandsService } from '../../services/demands/demands.service';
import { UserService } from '../../services/user-management/user.service';

@Component({
  selector: 'app-field-recursive',
  templateUrl: './field-recursive.component.html',
  styleUrls: ['./field-recursive.component.scss']
})
export class FieldRecursiveComponent {
  @Input() demand_id: number = 0;
  @Input() disableFields: boolean = false;
  @Input() field: any;
  @Input() area_id: any;

  currentUser:any;

  constructor(
    private demandService: DemandsService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.getLocalUser();
    // Aplica o filtro inicial nos filhos
    this.filterChildValues(this.field);

  }

  isSelected(child: any, field: any): boolean {
    return true;
  }

  isDisabled() { 
    let isDisabled = this.disableFields || 
    (this.field.user_group_permissions && 
     !this.field.user_group_permissions.id === this.currentUser.group.id
      && this.field.user_group_permissions.id.can_edit
    );
         
    // console.log(isDisabled);
    return isDisabled;
  }

  onItemSelect(field: any, fieldValue: any) {
    console.log(field);
    this.removeSelecteds(field);

    // Aplica o filtro nos filhos ao selecionar um item
    this.filterChildValues(field);

    this.demandService
      .saveModelFieldValue(this.demand_id, 'demand', {
        field_value_id: fieldValue.id,
        field_id: field.id
      })
      .subscribe({
        next: (result) => console.log(result),
        error: (err) => console.log(err)
      });
  }

  onItemDeSelect(field: any, fieldValue: any) {
    this.removeSelecteds(field);

    // Aplica o filtro nos filhos ao deselecionar um item
    this.filterChildValues(field);

    this.demandService
      .deleteModelFieldValue(this.demand_id, 'demand', {
        field_value_id: fieldValue.id,
        field_id: field.id
      })
      .subscribe({
        next: (result) => console.log(result),
        error: (err) => console.log(err)
      });
  }

  removeSelecteds(field: any): number[] {
    let ids: number[] = [];

    if (field.children && Array.isArray(field.children)) {
      field.children.forEach((child: any) => {
        if (child.selecteds) {
          child.selecteds = [];
        }
        this.removeSelecteds(child); // Chamada recursiva
      });
    }

    return ids;
  }

  filterChildValues(field: any) {
    if (field.children && Array.isArray(field.children)) {
      field.children.forEach((child: any) => {
        if (field.selecteds && field.selecteds.length > 0) {
          // Filtra os valores do filho com base nos itens selecionados no pai
          child.values = child.originalValues.filter((value: any) =>
            field.selecteds.some((selected: any) => selected.id === value.depends_on_value_id)
          );
        } else {
          // Caso não haja seleção, restaura os valores originais
          child.values = child.originalValues;
        }

        // Chamada recursiva para aplicar o filtro nos descendentes
        this.filterChildValues(child);
      });
    }
  }
}
