import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class PartsTypesService extends BaseService {

  getAll(filter: any) {
    return this.post(`parts/types/filter`, filter)
  }

  getById(id: number) {
    return this.get(`parts/types/${id}`)
  }

  save(data: FormData) {
    return this.post(`parts/types`, data)
  }

  deleteById(id: number) {
    return this.delete(`parts/types/${id}`)
  }

}
