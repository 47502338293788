import { Injectable } from '@angular/core';
import { BehaviorSubject, tap } from 'rxjs';
import { BaseService } from 'src/app/core/shared/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DemandEventsService extends BaseService {

  private newEvent = new BehaviorSubject<number | null>(null);
  newEvent$ = this.newEvent.asObservable();

  getAll(filter:any = {}) { 
    return this.post(`demands/events/filter`, filter);
  }

  getByDemandId(demand_id, filter:any = {}) { 
    return this.post(`demands/${demand_id}events/filter`, filter);
  }

  setNew(demandTaskId:number): void {
    this.newEvent.next(demandTaskId);
  }

  save(data: any) { 
    return this.post(`demand/events`, data).pipe(
      tap((response: any) => {
        if (response) {
          this.setNew(response);
        }
      })
    );
  }

  deleteById($event_id:number) { 
    return this.delete(`demand/events/${$event_id}`)
  }

  getTodayEvents() { 
    return this.get(`demand/events/today`);
  }

  test(data:any) { 
    return this.post(`demand/events/test`, data);
  }
}
