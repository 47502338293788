import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { DemandsService } from 'src/app/shared/services/demands/demands.service';
import Swal from 'sweetalert2';
import { FieldService } from '../../services/data/field.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user-management/user.service';
import { AreaService } from '../../services/user-management/area.service';
import { ProjectsService } from '../../services/demands/projects.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-requester-demands-form',
  templateUrl: './requester-demands-form.component.html',
  styleUrls: ['./requester-demands-form.component.scss']
})
export class RequesterDemandsFormComponent implements OnInit {
  
  skeleton = true;

  @Output() close = new EventEmitter();
  @Input() demand_id:number = null;

  //user
  user:any;

  //demand
  demandForm:any;
  loading =  false;

  area_id:any;
  clickSubmit = false;
  allFilled:any;
  projects:any;
  // project:any;
  // newProject:any;

  //fields
  fields:any;
  userFields:any;
  selectedCategories:any;

  //form
  formData = new FormData();

  countries = [
    {
      country: "Brasil",
      code: "+55",
      emoji: "🇧🇷"
    },
    {
      country: "Estados Unidos",
      code: "+1",
      emoji: "🇺🇸"
    },
    {
      country: "Argentina",
      code: "+54",
      emoji: "🇦🇷"
    },
    {
      country: "Portugal",
      code: "+351",
      emoji: "🇵🇹"
    },
    {
      country: "Espanha",
      code: "+34",
      emoji: "🇪🇸"
    }
]

areas:any = null;

  selectedCountry:any = 'BR';

  constructor(
    private formBuilder:FormBuilder,
    private demandService: DemandsService,
    private fieldService: FieldService,
    private userService: UserService,
    private areaService: AreaService,
    private projectService: ProjectsService,
    private modalService: NgbModal,
    private router: Router
  ) { }
  
  ngOnInit(): void {;
    // console.log(this.user)
    this.demandForm = this.formBuilder.group({
      'name':         ['',            Validators.required],
      'deadline':     ['',        Validators.required],
      'area_id':      ['',            Validators.required],
      'fields':       this.formBuilder.array([]),
      // 'project':      [null],
      'requester_description': ['', Validators.required],
    })
    
    if (this.demand_id) { 
      this.getById(this.demand_id)
      this.getOptions(this.demand_id);
    }
    else {
      this.user = this.userService.getLocalUser();
      this.getUserFields(this.user.id);
      this.getDemandFields();
    }

    
    // this.getProjects();
    this.getAreas();
  }

  getById(demand_id:number) { 
    this.demandService.getById(demand_id).subscribe({
      next: (result:any) => { 
        console.log(result);
        this.getUserFields(result.user.id);
        this.demandForm.patchValue(result);
      },
      error: (err) => console.log(err) 
    })
  }

  // getProjects() {
  //   this.projectService.getAll().subscribe({
  //     next: (result:any) => this.projects = result.data,
  //     error: (err) => console.log(err)
  //   })
  // }

  // setProject(value) { 
    // console.log(value);
    // console.log(this.project);
  // }

  setArea(area_id){ 
     this.demandForm.controls['area_id'].setValue(area_id);
  }

  onCheckboxFieldValueChange(field: any, optionId: any, event: any) {
    const isChecked = event.target.checked;

    if (field.multiple) {
      if (isChecked) {
        field.selecteds.push(optionId);
      } else {
        const index = field.selecteds.indexOf(optionId);
        if (index > -1) {
          field.selecteds.splice(index, 1);
        }
      }
    } else {
      field.selecteds = [optionId];
    }

    this.removeSelecteds(field);
    console.log(field)
  }

  hasValue(field: any): boolean {
    return field?.selecteds?.[0]?.value || (field?.children && field.children.some(this.hasValue.bind(this)));
  }
  

  getUserFields(user_id:number) {
    this.user = this.userService.getById(user_id).subscribe({
      next: (result) => { 
        console.log(result)
        this.user = result;
      },
      error: (err) => console.log(err)
    });
    this.fieldService.getFieldsAndValuesByModel(user_id, 'user', {hierarchy: true}).subscribe({
      next: (result: any) => {
        console.log(result);
        this.userFields = result;
        console.log(this.userFields);
        if (this.userFields) {
          this.userFields.forEach(field => this.addDropdownSettings(field));
        }
        // console.log(this.userFields);
      },
      error: (err) => console.log(err)
    });
  }

  filterChildValues(parentValueId: number, childValues: any[]) {
    return childValues.filter(value => value.depends_on_parent_value_id === parentValueId || value.depends_on_parent_value_id === null);
  }
  

  addDropdownSettings(field:any) {
    field.dropdownSettings = {
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll: false,
      showSelectedItemsAtTop: true,
      noDataAvailablePlaceholderText: 'Nenhuma opção selecionada',
      singleSelection: !field.multiple ? true : false
  };

    if (field.children && field.children.length > 0) {
        field.children.forEach(child => this.addDropdownSettings(child));
    }
  }

  onSelectFieldValueChange(field:any, event: any) {
    console.log(field);
    console.log(event);
    this.removeSelecteds(field);
  }

  onChangeAreaValue(area_id) {
    console.log(area_id) 
    this.fields.forEach(field => {
        if(field.area_id) {
          console.log(field);
          this.removeSelecteds(field);
        }
    });
  }

  getAreas() { 
    this.areas = this.areaService.getAll().subscribe({
      next: (result) => { 
        // console.log(result);
        this.areas = result;
      },
      error: (err) => console.log(err)
    })
  }

  getDemandFields(): void {
    this.skeleton = true;
    this.fieldService.getAll({ hierarchy: true, model_type: 'demand' }).subscribe({
      next: (result: any) => {
        this.fields = result.data;
        console.log(this.fields);
        this.skeleton = false;
        if (this.fields) {
          this.fields.forEach((c: any) => c.selecteds = undefined);
        }
        console.log(this.fields);
      },
      error: (err) => console.log(err)
    });
  }

  getOptions(demand_id: number) {
    this.demandService.getFields(demand_id, 'demand', { hierarchy: true, compact: true }).subscribe({
      next: (result: any) => {
        this.fields = result;
  
        if (this.fields) {
          this.fields.forEach(field => {
            this.addDropdownSettings(field);
  
            if (!field.multiple && Array.isArray(field.selecteds) && field.selecteds.length === 1) {
              // Transformar em um número simples pegando o `id`
              field.selecteds = field.selecteds[0].id;
            } else if (field.multiple && Array.isArray(field.selecteds)) {
              // Transformar em um array de números simples pegando o `id`
              field.selecteds = field.selecteds.map((selected: any) => selected.id);
            }
          });
  
          this.skeleton = false;
        }
        console.log(this.fields);
      },
      error: (err) => console.log(err)
    });
  }


  shouldDisplayChild(child: any, field: any): boolean {
    if (!field.multiple) {
      return (
        child.depends_on_value_id === field.selecteds || 
        child.parent_id === field.id
      );
    }
    return (
      field.selecteds.includes(child.depends_on_value_id) || 
      child.parent_id === field.id
    );
  }

  
  removeSelecteds(field: any): number[] {
    let ids: number[] = [];

    // Processa apenas as filhas
    if (field.children && Array.isArray(field.children)) {
        field.children.forEach((child: any) => {
            if (child.selecteds) {
                // console.log(child.selecteds);
                child.selecteds = undefined;
            }
            // Chamada recursiva para processar os descendentes
            this.removeSelecteds(child);
        });
    }

    return ids;
  };

  getFieldsValues() {
    console.log("get fields")
    let selecteds: any[] = [];
  
    const collectSelecteds = (field: any) => {
      // console.log(field);
      if (field.selecteds) {
        // console.log(field.selecteds)
        selecteds = selecteds.concat(field.selecteds);  // Achata o array
        // console.log(field.selecteds);
      }
  
      if (field.children) {
        field.children.forEach((child: any) => collectSelecteds(child));
      }
    };
  
    this.fields.forEach((field: any) => collectSelecteds(field));
  
    console.log(selecteds);
  
    selecteds.forEach(id => {
      this.formData.append("fields[]", id);
    });
  }

  // appendFormData(data: any, parentKey: string) {
  //   if (data && typeof data === 'object' && !(data instanceof File)) {
  //     Object.keys(data).forEach(key => {
  //       const fullKey = parentKey ? `${parentKey}[${key}]` : key;
  //       this.appendFormData(data[key], fullKey);
  //     });
  //   } else {
  //     this.formData.append(parentKey, data);
  //   }
  // }

  save(data: any, duplicate = false) {

    this.clickSubmit = true;

    if (!this.allFilled) { 
      console.log('falta ainda preencher')
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'Preencha todos os campos',
        showConfirmButton: false,
        timer: 3000
      });
    } 
    else { 
      console.log('já estão preenchidos')
      this.loading = true

      Object.keys(data.controls).forEach(key => {
        const value = data.get(key)?.value;

        // Verifica se o valor não é nulo ou indefinido
        if (value !== null && value !== undefined) {
          this.formData.append(key, value);
        }
      })

      this.getFieldsValues();

      if (!this.demand_id && !duplicate) { 
        this.demandService.save(this.formData).subscribe({
          next: (result: any) => {
            console.log(result);
    
            this.loading = false;
    
            Swal.fire({
              position: 'center',
              icon: 'success',
              text: 'Solicitação enviada com sucesso',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              console.log('salvou')
                this.close.emit();
                this.router.navigateByUrl(`/requester/demands`);
            })
    
          },
          error: (err) => {
            console.log(err)
            this.loading = false
          }
        })
      } else { 
        console.log(duplicate);
        this.demandService.duplicate(this.demand_id, this.formData).subscribe({
          next: (result: any) => {
            console.log(result);
            this.loading = false;
    
            Swal.fire({
              position: 'center',
              icon: 'success',
              text: 'Solicitação enviada com sucesso',
              showConfirmButton: false,
              timer: 3000
            }).then(() => {
              console.log('salvou')
                this.close.emit();
            })
    
          },
          error: (err) => {
            console.log(err)
            this.loading = false
          }
        })
      }
    }
  }
}
