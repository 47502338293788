import { Component, OnInit } from '@angular/core';
import { MetricsService } from 'src/app/shared/services/data/metrics.service';
import { StatusService } from 'src/app/shared/services/demands/status.service';
import { UserService } from 'src/app/shared/services/user-management/user.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  
  skeleton = true
  status = ''
  users: any = []
  statusList: any = []
  manager: any = ''
  data: any
  parts: any
  start = ''
  end = ''

  chartOptions: any = null
  chartPartsOptions: any = null

  total: any

  qtdDemands = 0
  qtdParts   = 0

  exportLoading = false

  constructor(
    private metricsService: MetricsService,
    private userService: UserService,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    this.getDemandFielsdMetrics()
    // this.getUsers()
    // this.getStatus()
    // this.getStatusMetrics()
    // this.getParts()
    // this.getTotal()
  }

  getTotal() {
    this.metricsService.getTotalDemandsAndParts(
      {
        start:   this.start,
        end:     this.end,
        status:  this.status,
        user_id: this.manager
      }
    ).subscribe({
      next: (result: any) => {
        this.total = result

        this.qtdDemands = 0 
        this.qtdParts   = 0

       let countDemands: any = setInterval(() => {
        if (result.qtdDemands > 0) this.qtdDemands++
        if(this.qtdDemands == result.qtdDemands) {
          clearInterval(countDemands)
        }
       }, 5)

       let countParts: any = setInterval(() => {
        this.qtdParts++
        if(this.qtdParts == result.qtdParts) {
          clearInterval(countParts)
        }
       }, 5)

      },
      error: (err: any) => console.log(err)
    })
  }

  getParts() {
    this.chartPartsOptions = null
    this.metricsService.getParts(
      {
        start:   this.start,
        end:     this.end,
        status:  this.status,
        user_id: this.manager
      }
    ).subscribe({
      next: (result: any) => {
        console.log(result)
        this.parts = result

        let series: any = []
        let labels: any = []
        let colors: any = []

        result.forEach((e: any) => {
          if(e.total > 0) {
            series.push(e.total)
            labels.push(e.format)
          }
        });

        this.chartPartsOptions = {
          series: [
            {
              name: "Peças",
              data: series
            }
          ],
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          chart: {
            type: "bar",
            height: 600,
            toolbar: {
              show: false
            },
          },
          theme: {
            palette: 'palette1' // upto palette10
          },
          plotOptions: {
            bar: {
              distributed: true,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: true
          },
          xaxis: {
            fields: labels
          },
          legend: {
            show: false
          },
          grid: {
            show: false
          },
        };
      },
      error: (err: any) => console.log(err)
    })
  }

  getDemandFielsdMetrics() {
    this.skeleton = true
    this.metricsService.getDemandFielsdMetrics({
      status: this.status,
      start: this.start,
      end: this.end,
      user_id: this.manager
    }).subscribe({
      next: (result: any) => {
        this.data = result
        this.skeleton = false
      },
      error: (err: any) => console.log(err)
    })
  }

  getStatus() {
    this.statusService.getAll().subscribe({
      next: (result: any) => {
        this.statusList = result.data
      },
      error: (err) => console.log(err)
    })
  }

  getStatusMetrics() {
    this.chartOptions = null
    this.statusService.getMetrics({
      status: this.status,
      start: this.start,
      end: this.end,
      user_id: this.manager
    }).subscribe({
      next: (result: any) => {

        let series: any = []
        let labels: any = []
        let colors: any = []
    
        result.forEach((e: any) => {
          if(e.total > 0) {
            series.push(e.total)
            labels.push(`${e.name} (${e.total})`)
            colors.push(e.color)
          }
        });
    
        this.chartOptions = {
          series: series,
          chart: {
            type: "pie",
            height: 450,
            animations: {
              enabled: false
            }
          },
          noData: {
            text: "Nenhum dado para exibir",
            align: "center"
          },
          labels: labels,
          fill: {
            opacity: 1
          },
          colors: colors
        };
      },
      error: (err) => console.log(err)
    })
  }

  getUsers() {
    this.userService.getAll().subscribe({
      next: (result: any) =>  this.users = result.data,
      error: (err) => console.log(err)
    })
  }

  filter() {
    this.skeleton = true
    this.getDemandFielsdMetrics()
    this.getStatusMetrics()
    this.getParts()
    this.getTotal()
  }

  print() {
    window.print()
  }

  export() {
    this.exportLoading = true
    this.metricsService.export({}).subscribe({
      next: (result: any) => {
        this.exportLoading = false
        window.open(result.file, 'blank')
      },
      error: (err) => console.log(err)
    })
  }

}
